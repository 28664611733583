import { Grid } from "@mui/material";
import CrudBase from "src/components/CrudBase";
import PageHeader from "src/components/PageHeader";
import { useTranslation } from "react-i18next";
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AircraftTypesForm from "./AircraftTypesForm";

export function AircraftTypes() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="aircraft-types"
            indexColumns={['name']}
            form={AircraftTypesForm}
            icon={<ConnectingAirportsIcon fontSize="large" />}
            showButton={false} />
    );
}
