import Footer from "src/components/Footer";
import { Grid } from "@mui/material";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTranslation } from "react-i18next";
import PageHeader from "src/components/PageHeader";
import CrudBase from "src/components/CrudBase";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import GasolineInventoryMovementsForm from "./GasolineInventoryMovementsForm";
import InventoryIcon from '@mui/icons-material/Inventory';

export function GasolineInventoryMovements() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="gasoline-inventory-movements"
            indexColumns={['created_at', 'movement_type', 'movement_quantity', 'movement_price', 'movement_total', 'comment']}
            form={GasolineInventoryMovementsForm}
            deleteButton={false}
            searchText={true}
            showButton={false}
            actions={false}
            icon={<InventoryIcon fontSize="large" />} />
    );
}
