import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
    DialogActions,
    DialogContent,
    Zoom,
    TextField,
    CircularProgress,
    Button,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    FormHelperText,
    Select,
    InputLabel,
    MenuItem,
    ListSubheader,
    Avatar,
    IconButton,
    styled,
    Autocomplete
} from "@mui/material";
import { useSnackbar } from "notistack";
import Checkbox from '@mui/material/Checkbox';
import http from "src/utils/httpHelper";
import { Box } from "@mui/system";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import anyToString from "src/utils/anyToString";
import useAuth from "src/hooks/useAuth";
import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";

interface PilotAircraftsFormProps {
    modalCloseCallback,
    onSuccessCallback,
    data?: any,
    setData: any
}

const Input = styled('input')({
    display: 'none'
});

const AvatarWrapper = styled(Box)(
    ({ theme }) => `
        position: relative;

        .MuiAvatar-root {
            width: ${theme.spacing(35)};
            height: ${theme.spacing(15)};
        }
  `
);

const ButtonUploadWrapper = styled(Box)(
    ({ theme }) => `
        position: absolute;
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        bottom: -${theme.spacing(2)};
        right: -${theme.spacing(2)};

        .MuiIconButton-root {
            border-radius: 100%;
            background: ${theme.colors.primary.main};
            color: ${theme.palette.primary.contrastText};
            box-shadow: ${theme.colors.shadows.primary};
            width: ${theme.spacing(6)};
            height: ${theme.spacing(6)};
            padding: 0;

            &:hover {
                background: ${theme.colors.primary.dark};
            }
        }
    `
);

const rebuildData = (values, edit = false) => {
    let formData = new FormData();
    Object.keys(values).forEach(key => {
        if (values[key]) {
            if (Array.isArray(values[key])) {
                values[key].forEach(element => {
                    formData.append(key + '[]', element);
                });
            } else {
                formData.append(key, values[key]);
            }
        }
    });
    if (edit) {
        formData.append('_method', 'PUT');
    }
    return formData;
};

const PilotAircraftsForm: FC<PilotAircraftsFormProps> = ({ modalCloseCallback, onSuccessCallback, data, setData }) => {
    const [airworthinessCertificatePhoto, setAirworthinessCertificatePhoto] = useState(null);
    const [insurancePhotoPreview, setInsurancePhotoPreview] = useState(null);
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const { user } = useAuth();

    useEffect(() => {
        if (user.can('pilot-aircrafts.create')) {
            http.get('api/get-aircraft-types-for-select')
                .then(response => setAircraftTypes(response.data))
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' });
                });
        }
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    company: data ? data.company : "",
                    brand: data ? data.brand : "",
                    model: data ? data.model : "",
                    plate: data ? data.plate : "",
                    type_of_aircraft: data ? data.type_of_aircraft : '',
                    wake_turbulence_cat: data ? data.wake_turbulence_cat : '',
                    radiocomunication: data ? data.radiocomunication : [],
                    surveilance: data ? data.surveilance : [],
                    emergency_radio: data ? data.emergency_radio : [],
                    surveliance_equipment: data ? data.surveliance_equipment : [],
                    crusising_speed: data ? (data.crusising_speed ?? '') : '',
                    jackets: data ? data.jackets : [],
                    dinghles: data ? data.dinghles : [],
                    dinghles_number: data ? (data.dinghles_number ?? '') : '',
                    dinghles_capacity: data ? (data.dinghles_capacity ?? '') : '',
                    dinghles_cover: data ? data.dinghles_cover : [],
                    dinghles_color: data ? (data.dinghles_color ?? '') : '',
                    aircraft_colour_and_markings: data ? data.aircraft_colour_and_markings : '',
                    remarks: data ? (data.remarks ?? '') : '',
                    airworthiness_certificate_photo: null,
                    insurance_photo: null,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    company: Yup.string()
                        .max(191)
                        .required(t("VALIDATION.REQUIRED")),
                    brand: Yup.string()
                        .max(191)
                        .required(t("VALIDATION.REQUIRED")),
                    model: Yup.string()
                        .max(191)
                        .required(t("VALIDATION.REQUIRED")),
                    plate: Yup.string()
                        .max(191)
                        .required(t("VALIDATION.REQUIRED"))
                        .matches(/^[a-zA-Z0-9]*$/, 'Formato invalido, solo se aceptan números y letras'),
                    type_of_aircraft: Yup.string().required(t('VALIDATION.REQUIRED')).max(4, 'Máximo 4 caracteres'),
                    wake_turbulence_cat: Yup.string().required(t('VALIDATION.REQUIRED')),
                    radiocomunication: Yup.array().required(t('VALIDATION.REQUIRED')).min(1, 'Es necesario seleccionar al menos 1'),
                    surveilance: Yup.array().required(t('VALIDATION.REQUIRED')).min(1, 'Es necesario seleccionar al menos 1'),
                    emergency_radio: Yup.array(),
                    surveliance_equipment: Yup.array(),
                    crusising_speed: Yup.string().required(t('VALIDATION.REQUIRED')).max(5, 'Máximo 5 caracteres'),
                    jackets: Yup.array(),
                    dinghles: Yup.array(),
                    dinghles_number: Yup.string().matches(/^([0-9][0-9])$/, 'Formato invalido, debe ser número de 2 digitos'),
                    dinghles_capacity: Yup.string().matches(/^([0-9][0-9][0-9])$/, 'Formato invalido, debe ser número de 3 digitos'),
                    dinghles_cover: Yup.array(),
                    dinghles_color: Yup.string(),
                    aircraft_colour_and_markings: Yup.string().required(t('VALIDATION.REQUIRED')),
                    remarks: Yup.string(),
                    airworthiness_certificate_photo: data ? null : Yup.mixed()
                        .required(t('VALIDATION.REQUIRED')),
                    insurance_photo: data ? null : Yup.mixed()
                        .required(t('VALIDATION.REQUIRED')),
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        http.post('api/pilot-aircrafts/' + data.id, rebuildData(_values, true))
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(t('PILOT-AIRCRAFTS.EDITED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        http.post('api/pilot-aircrafts', rebuildData(_values, false))
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback()
                                enqueueSnackbar(t('PILOT-AIRCRAFTS.SAVED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.company && errors.company
                                        )}
                                        fullWidth
                                        helperText={touched.company && errors.company && errors.company.toString()}
                                        label={t("FIELDS.COMPANY")}
                                        name="company"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.company}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.brand && errors.brand
                                        )}
                                        fullWidth
                                        helperText={touched.brand && errors.brand && errors.brand.toString()}
                                        label={t("FIELDS.BRAND")}
                                        name="brand"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.brand}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.model && errors.model
                                        )}
                                        fullWidth
                                        helperText={touched.model && errors.model && errors.model.toString()}
                                        label={t("FIELDS.MODEL")}
                                        name="model"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.model}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.plate && errors.plate
                                        )}
                                        fullWidth
                                        helperText={touched.plate && errors.plate && errors.plate.toString()}
                                        label={t("FIELDS.PLATE")}
                                        name="plate"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.plate}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        options={aircraftTypes}
                                        freeSolo={true}
                                        onChange={(_, option: any) => setFieldValue('type_of_aircraft', option ?? '')}
                                        value={values.type_of_aircraft}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.TYPE_OF_AIRCRAFT')}
                                                error={Boolean(touched.type_of_aircraft && errors.type_of_aircraft)}
                                                helperText={touched.type_of_aircraft && errors.type_of_aircraft && errors.type_of_aircraft.toString()}
                                                onChange={handleChange}
                                                name={'type_of_aircraft'}
                                                onBlur={handleBlur}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="wake-turbulance-cat-label">{t("FIELDS.WAKE_TURBULENCE_CAT")}</InputLabel>
                                        <Select
                                            labelId="wake-turbulance-cat-label"
                                            name="wake_turbulence_cat"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.wake_turbulence_cat}
                                            error={Boolean(touched.wake_turbulence_cat && errors.wake_turbulence_cat)}
                                            label={t("FIELDS.WAKE_TURBULENCE_CAT")}
                                            variant="outlined"
                                        >
                                            <MenuItem value={'H'}>H</MenuItem>
                                            <MenuItem value={'M'}>M</MenuItem>
                                            <MenuItem value={'L'}>L</MenuItem>
                                        </Select>
                                        {
                                            Boolean(touched.wake_turbulence_cat && errors.wake_turbulence_cat) &&
                                            <FormHelperText error={true}>{touched.wake_turbulence_cat && errors.wake_turbulence_cat && errors.wake_turbulence_cat.toString()}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4.5}>
                                    <FormControl fullWidth>
                                        <InputLabel id="radiocomunication-label">{t("FIELDS.RADIOCOMUNICATION")}</InputLabel>
                                        <Select
                                            labelId="radiocomunication-label"
                                            name="radiocomunication"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.radiocomunication}
                                            error={Boolean(touched.radiocomunication && errors.radiocomunication)}
                                            label={t("FIELDS.RADIOCOMUNICATION")}
                                            variant="outlined"
                                            multiple={true}
                                        >
                                            <MenuItem value={'N'}>N</MenuItem>
                                            <MenuItem value={'S'}>S</MenuItem>
                                            <MenuItem value={'A'}>A</MenuItem>
                                            <MenuItem value={'B'}>B</MenuItem>
                                            <MenuItem value={'C'}>C</MenuItem>
                                            <MenuItem value={'D'}>D</MenuItem>
                                            <MenuItem value={'E1'}>E1</MenuItem>
                                            <MenuItem value={'E2'}>E2</MenuItem>
                                            <MenuItem value={'E3'}>E3</MenuItem>
                                            <MenuItem value={'F'}>F</MenuItem>
                                            <MenuItem value={'G'}>G</MenuItem>
                                            <MenuItem value={'H'}>H</MenuItem>
                                            <MenuItem value={'I'}>I</MenuItem>
                                            <MenuItem value={'J1'}>J1</MenuItem>
                                            <MenuItem value={'J2'}>J2</MenuItem>
                                            <MenuItem value={'J3'}>J3</MenuItem>
                                            <MenuItem value={'J4'}>J4</MenuItem>
                                            <MenuItem value={'J5'}>J5</MenuItem>
                                            <MenuItem value={'J6'}>J6</MenuItem>
                                            <MenuItem value={'J7'}>J7</MenuItem>
                                            <MenuItem value={'K'}>K</MenuItem>
                                            <MenuItem value={'L'}>L</MenuItem>
                                            <MenuItem value={'M1'}>M1</MenuItem>
                                            <MenuItem value={'M2'}>M2</MenuItem>
                                            <MenuItem value={'M3'}>M3</MenuItem>
                                            <MenuItem value={'O'}>O</MenuItem>
                                            <MenuItem value={'P1-P9'}>P1-P9</MenuItem>
                                            <MenuItem value={'R'}>R</MenuItem>
                                            <MenuItem value={'T'}>T</MenuItem>
                                            <MenuItem value={'U'}>U</MenuItem>
                                            <MenuItem value={'V'}>V</MenuItem>
                                            <MenuItem value={'W'}>W</MenuItem>
                                            <MenuItem value={'X'}>X</MenuItem>
                                            <MenuItem value={'Y'}>Y</MenuItem>
                                            <MenuItem value={'Z'}>Z</MenuItem>
                                        </Select>
                                        {
                                            Boolean(touched.radiocomunication && errors.radiocomunication) &&
                                            <FormHelperText error={true}>{touched.radiocomunication && errors.radiocomunication && errors.radiocomunication.toString()}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4.5}>
                                    <FormControl fullWidth>
                                        <InputLabel id="surveilance-label">{t("FIELDS.SURVEILANCE")}</InputLabel>
                                        <Select
                                            labelId="surveilance-label"
                                            name="surveilance"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.surveilance}
                                            error={Boolean(touched.surveilance && errors.surveilance)}
                                            label={t("FIELDS.SURVEILANCE")}
                                            variant="outlined"
                                            multiple={true}
                                        >
                                            <MenuItem value={'N'}>N</MenuItem>
                                            <ListSubheader>SSR en Modos A y C</ListSubheader>
                                            <MenuItem value={'A'}>A</MenuItem>
                                            <MenuItem value={'C'}>C</MenuItem>
                                            <ListSubheader>SSR modo S</ListSubheader>
                                            <MenuItem value={'E'}>E</MenuItem>
                                            <MenuItem value={'H'}>H</MenuItem>
                                            <MenuItem value={'I'}>I</MenuItem>
                                            <MenuItem value={'L'}>L</MenuItem>
                                            <MenuItem value={'P'}>P</MenuItem>
                                            <MenuItem value={'S'}>S</MenuItem>
                                            <MenuItem value={'X'}>X</MenuItem>
                                            <ListSubheader>ADS-B</ListSubheader>
                                            <MenuItem value={'B1'}>B1</MenuItem>
                                            <MenuItem value={'B2'}>B2</MenuItem>
                                            <MenuItem value={'U1'}>U1</MenuItem>
                                            <MenuItem value={'U2'}>U2</MenuItem>
                                            <MenuItem value={'V1'}>V1</MenuItem>
                                            <MenuItem value={'V2'}>V2</MenuItem>
                                            <ListSubheader>ADS-C</ListSubheader>
                                            <MenuItem value={'D1'}>D1</MenuItem>
                                            <MenuItem value={'G1'}>G1</MenuItem>
                                        </Select>
                                        {
                                            Boolean(touched.surveilance && errors.surveilance) &&
                                            <FormHelperText error={true}>{touched.surveilance && errors.surveilance && errors.surveilance.toString()}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        error={Boolean(touched.crusising_speed && errors.crusising_speed)}
                                        fullWidth
                                        helperText={touched.crusising_speed && errors.crusising_speed && errors.crusising_speed.toString()}
                                        label={t("FIELDS.CRUSISING_SPEED")}
                                        name="crusising_speed"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.crusising_speed}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormGroup>
                                        <FormLabel component="legend">{t("FIELDS.EMERGENCY_RADIO")}</FormLabel>
                                        <Box display={'flex'} justifyContent={'space-evenly'}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="emergency_radio" checked={values.emergency_radio.includes('U')} value={'U'} />
                                                }
                                                label={'U'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="emergency_radio" checked={values.emergency_radio.includes('V')} value={'V'} />
                                                }
                                                label={'V'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="emergency_radio" checked={values.emergency_radio.includes('E')} value={'E'} />
                                                }
                                                label={'E'}
                                            />
                                        </Box>
                                    </FormGroup>
                                    {Boolean(touched.emergency_radio && errors.emergency_radio) && <FormHelperText error={true}>{touched.emergency_radio && errors.emergency_radio && errors.emergency_radio.toString()}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormGroup>
                                        <FormLabel component="legend">{t("FIELDS.SURVELIANCE_EQUIPMENT")}</FormLabel>
                                        <Box display={'flex'} justifyContent={'space-evenly'}>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="surveliance_equipment" checked={values.surveliance_equipment.includes('S')} value={'S'} />
                                                }
                                                label={'S'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="surveliance_equipment" checked={values.surveliance_equipment.includes('P')} value={'P'} />
                                                }
                                                label={'P'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="surveliance_equipment" checked={values.surveliance_equipment.includes('D')} value={'D'} />
                                                }
                                                label={'D'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="surveliance_equipment" checked={values.surveliance_equipment.includes('M')} value={'M'} />
                                                }
                                                label={'M'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="surveliance_equipment" checked={values.surveliance_equipment.includes('J')} value={'J'} />
                                                }
                                                label={'J'}
                                            />
                                        </Box>
                                    </FormGroup>
                                    {Boolean(touched.surveliance_equipment && errors.surveliance_equipment) && <FormHelperText error={true}>{touched.surveliance_equipment && errors.surveliance_equipment && errors.surveliance_equipment.toString()}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormGroup>
                                        <FormLabel component="legend">{t("FIELDS.JACKETS")}</FormLabel>
                                        <Box display={'flex'} justifyContent={'space-evenly'}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="jackets" checked={values.jackets.includes('J')} value={'J'} />
                                                }
                                                label={'J'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="jackets" checked={values.jackets.includes('L')} value={'L'} />
                                                }
                                                label={'L'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="jackets" checked={values.jackets.includes('F')} value={'F'} />
                                                }
                                                label={'F'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="jackets" checked={values.jackets.includes('U')} value={'U'} />
                                                }
                                                label={'U'}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checkedIcon={<DisabledByDefaultIcon />} size="small" onChange={handleChange} name="jackets" checked={values.jackets.includes('V')} value={'V'} />
                                                }
                                                label={'V'}
                                            />
                                        </Box>
                                    </FormGroup>
                                    {Boolean(touched.jackets && errors.jackets) && <FormHelperText error={true}>{touched.jackets && errors.jackets && errors.jackets.toString()}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormLabel component="legend">{t("FIELDS.DINGHLES")}</FormLabel>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checkedIcon={<DisabledByDefaultIcon />} onChange={handleChange} name="dinghles" checked={values.dinghles.includes('D')} value={'D'} />
                                        }
                                        label={'D'}
                                    />
                                    {Boolean(touched.dinghles && errors.dinghles) && <FormHelperText error={true}>{touched.dinghles && errors.dinghles && errors.dinghles.toString()}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        error={Boolean(touched.dinghles_number && errors.dinghles_number)}
                                        fullWidth
                                        helperText={touched.dinghles_number && errors.dinghles_number && errors.dinghles_number.toString()}
                                        label={t("FIELDS.DINGHLES_NUMBER")}
                                        name="dinghles_number"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.dinghles_number}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        error={Boolean(touched.dinghles_capacity && errors.dinghles_capacity)}
                                        fullWidth
                                        helperText={touched.dinghles_capacity && errors.dinghles_capacity && errors.dinghles_capacity.toString()}
                                        label={t("FIELDS.DINGHLES_CAPACITY")}
                                        name="dinghles_capacity"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.dinghles_capacity}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checkedIcon={<DisabledByDefaultIcon />} onChange={handleChange} name="dinghles_cover" checked={values.dinghles_cover.includes('C')} value={'C'} />
                                        }
                                        label={'C'}
                                    />
                                    {Boolean(touched.dinghles_cover && errors.dinghles_cover) && <FormHelperText error={true}>{touched.dinghles_cover && errors.dinghles_cover && errors.dinghles_cover.toString()}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.dinghles_color && errors.dinghles_color)}
                                        fullWidth
                                        helperText={touched.dinghles_color && errors.dinghles_color && errors.dinghles_color.toString()}
                                        label={t("FIELDS.DINGHLES_COLOR")}
                                        name="dinghles_color"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.dinghles_color}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.aircraft_colour_and_markings && errors.aircraft_colour_and_markings)}
                                        fullWidth
                                        helperText={touched.aircraft_colour_and_markings && errors.aircraft_colour_and_markings && errors.aircraft_colour_and_markings.toString()}
                                        label={t("FIELDS.AIRCRAFT_COLOUR_AND_MARKINGS")}
                                        name="aircraft_colour_and_markings"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.aircraft_colour_and_markings}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.remarks && errors.remarks)}
                                        fullWidth
                                        helperText={touched.remarks && errors.remarks && errors.remarks.toString()}
                                        label={t("FIELDS.REMARKS")}
                                        name="remarks"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.remarks}
                                        variant="outlined"
                                    />
                                </Grid><Grid item sm={12} md={6} lg={6}>
                                    <FormLabel>{t('FIELDS.AIRWORTHINESS_CERTIFICATE_PHOTO')}</FormLabel>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="row"
                                        mt={3}
                                    >
                                        <AvatarWrapper>
                                            {(data?.airworthiness_certificate_photo_base_64?.mime != 'application/pdf' && airworthinessCertificatePhoto?.mime != 'application/pdf') ?
                                                <Avatar
                                                    style={{ display: 'grid', overflow: 'overlay' }}
                                                    variant="rounded"
                                                    alt={'placeholder'}
                                                    src={airworthinessCertificatePhoto ? airworthinessCertificatePhoto?.content : (data ? data.airworthiness_certificate_photo_base_64?.content : null)}
                                                >{t('FIELDS.AIRWORTHINESS_CERTIFICATE_PHOTO')}</Avatar>
                                                : <object
                                                    data={airworthinessCertificatePhoto ? airworthinessCertificatePhoto?.content : (data ? data.airworthiness_certificate_photo_base_64?.content : null)}
                                                />
                                            }
                                            {
                                                (!data?.airworthiness_certificate_photo_base_64?.content && !airworthinessCertificatePhoto?.content) ?
                                                    <ButtonUploadWrapper>
                                                        <Input
                                                            accept="application/pdf, image/*"
                                                            id="airworthiness-certificate-photo-icon-button-file"
                                                            type="file"
                                                            onBlur={() => setTouched({ ['airworthiness_certificate_photo']: true })}
                                                            onChange={(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setFieldValue('delete_airworthiness_certificate_photo', null);
                                                                setFieldValue('airworthiness_certificate_photo', file);
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setAirworthinessCertificatePhoto({ content: reader.result, mime: file.type });
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }}
                                                        />
                                                        <label htmlFor="airworthiness-certificate-photo-icon-button-file">
                                                            <IconButton component="span" color="primary">
                                                                <CloudUploadTwoToneIcon />
                                                            </IconButton>
                                                        </label>
                                                    </ButtonUploadWrapper> :
                                                    <ButtonUploadWrapper>
                                                        <label htmlFor="insurance-button-file">
                                                            <IconButton component="span" color="error" onClick={
                                                                () => {
                                                                    setAirworthinessCertificatePhoto(null);
                                                                    setFieldValue('airworthiness_certificate_photo', null);
                                                                    setData({ ...data, airworthiness_certificate_photo_base_64: '' });
                                                                    setFieldValue('delete_airworthiness_certificate_photo', 'true');
                                                                }
                                                            }>
                                                                <DeleteTwoTone />
                                                            </IconButton>
                                                        </label>
                                                    </ButtonUploadWrapper>
                                            }
                                        </AvatarWrapper>
                                    </Box>
                                    <FormHelperText sx={{ textAlign: 'center' }} error>
                                        {touched.airworthiness_certificate_photo && errors.airworthiness_certificate_photo && errors.airworthiness_certificate_photo.toString()}
                                    </FormHelperText>
                                </Grid>
                                <Grid item sm={12} md={6} lg={6}>
                                    <FormLabel>{t('FIELDS.INSURANCE_PHOTO')}</FormLabel>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="row"
                                        mt={3}
                                    >
                                        <AvatarWrapper>
                                            {
                                                (data?.insurance_photo_base_64?.mime != 'application/pdf' && insurancePhotoPreview?.mime != 'application/pdf') ?
                                                    <Avatar
                                                        style={{ display: 'grid', overflow: 'overlay' }}
                                                        variant="rounded"
                                                        alt={'placeholder'}
                                                        src={insurancePhotoPreview ? insurancePhotoPreview?.content : (data ? data.insurance_photo_base_64?.content : null)}
                                                    >{t('FIELDS.INSURANCE_PHOTO')}</Avatar>
                                                    : <object
                                                        data={insurancePhotoPreview ? insurancePhotoPreview?.content : (data ? data.insurance_photo_base_64?.content : null)}
                                                    />
                                            }
                                            {
                                                (!data?.insurance_photo_base_64?.content && !insurancePhotoPreview?.content) ?
                                                    <ButtonUploadWrapper>
                                                        <Input
                                                            accept="application/pdf, image/*"
                                                            id="insurance-button-file"
                                                            type="file"
                                                            onBlur={() => setTouched({ ['insurance_photo']: true })}
                                                            onChange={(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                setFieldValue('insurance_photo', null);
                                                                setFieldValue('insurance_photo', file);
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setInsurancePhotoPreview({ content: reader.result, mime: file.type });
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }}
                                                        />
                                                        <label htmlFor="insurance-button-file">
                                                            <IconButton component="span" color="primary">
                                                                <CloudUploadTwoToneIcon />
                                                            </IconButton>
                                                        </label>
                                                    </ButtonUploadWrapper> :
                                                    <ButtonUploadWrapper>
                                                        <label htmlFor="insurance-button-file">
                                                            <IconButton component="span" color="error" onClick={
                                                                () => {
                                                                    setInsurancePhotoPreview(null);
                                                                    setFieldValue('insurance_photo', null);
                                                                    setData({ ...data, insurance_photo_base_64: '' });
                                                                    setFieldValue('delete_insurance_photo', 'true');
                                                                }
                                                            }>
                                                                <DeleteTwoTone />
                                                            </IconButton>
                                                        </label>
                                                    </ButtonUploadWrapper>
                                            }
                                        </AvatarWrapper>
                                    </Box>
                                    <FormHelperText sx={{ textAlign: 'center' }} error>
                                        {touched.insurance_photo && errors.insurance_photo && errors.insurance_photo.toString()}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                            }}
                        >
                            <Button color="secondary" onClick={modalCloseCallback}>
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={Boolean(errors.submit) || isSubmitting}
                                variant="contained"
                            >
                                {t(!data ? "PILOT-AIRCRAFTS.CREATE" : 'PILOT-AIRCRAFTS.EDIT')}
                            </Button>
                        </DialogActions>
                    </form>
                )
                }
            </Formik >
        </>
    )
}

export default PilotAircraftsForm;