
import CrudBase from "src/components/CrudBase";
import OperationBasesForm from "./OperationBasesForm";
import BusinessIcon from '@mui/icons-material/Business';

export function OperationBases() {
    return (
        <CrudBase
            resource="operation-bases"
            indexColumns={['name']}
            icon={<BusinessIcon fontSize="large" />}
            form={OperationBasesForm}
            showButton={false} />
    );
}
