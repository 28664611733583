import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "src/hooks/useScrollTop";

import { createRoot } from "react-dom/client";
/* import { Provider } from "react-redux"; */
/* import store from "src/store"; */
import App from "src/App";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/serviceWorker";
import { AuthProvider } from "src/contexts/LaravelCookieAuthContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <ScrollTop />
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
