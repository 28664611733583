import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    ListSubheader,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import moment from "moment";
import useAuth from "src/hooks/useAuth";
import i18n from "src/i18n/i18n";

interface FlightPlansFormProps {
    modalCloseCallback,
    onSuccessCallback,
    data?: any
}

const validationSchema = Yup.object().shape({
    arrive_or_depart: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    company: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    operation_type: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    national_or_international: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    aircraft_brand: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    aircraft_model: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    aircraft_plate: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^[a-zA-Z0-9]*$/, 'Formato invalido, solo se aceptan números y letras'),
    operation_time: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^(0[0-9]|[0-9][0-9]):[0-5][0-9]$/, 'Formato invalido, debe ser HH:MM'),
    date: Yup.date().required(i18n.t('VALIDATION.REQUIRED')),
    aircraft_identification: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^[a-zA-Z0-9]*$/, 'Formato invalido, solo se aceptan números y letras').max(7, 'Máximo 7 caracteres'),
    flight_rules: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    type_of_flight: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    aircraft_number: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^([0-9][0-9])$/, 'Formato invalido, debe ser número de 2 digitos'),
    type_of_aircraft: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).max(4, 'Máximo 4 caracteres'),
    wake_turbulence_cat: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    radiocomunication: Yup.array().required(i18n.t('VALIDATION.REQUIRED')).min(1, 'Es necesario seleccionar al menos 1'),
    surveilance: Yup.array().required(i18n.t('VALIDATION.REQUIRED')).min(1, 'Es necesario seleccionar al menos 1'),
    departure_aerodrome: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).min(4, 'Mínimo 4 caracteres').max(4, 'Máximo 4 caracteres'),
    crusising_speed: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).max(5, 'Máximo 5 caracteres'),
    level: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).max(5, 'Máximo 5 caracteres'),
    route: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    destination_aerodrome: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).min(4, 'Mínimo 4 caracteres').max(4, 'Máximo 4 caracteres'),
    departure_time: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^(0[0-9]|[0-9][0-9]):[0-5][0-9]$/, 'Formato invalido, debe ser HH:MM'),
    total_eet: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^(0[0-9]|[0-9][0-9]):[0-5][0-9]$/, 'Formato invalido, debe ser HH:MM'),
    altn_aerodrome: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).min(4, 'Mínimo 4 caracteres').max(4, 'Máximo 4 caracteres'),
    second_altn_aerodrome: Yup.string().nullable().when('second_altn_aerodrome', {
        is: value => value?.length,
        then: rule => rule.min(4, 'Mínimo 4 caracteres').max(4, 'Máximo 4 caracteres')
    }),
    other_information: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    endurance: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^(0[0-9]|[0-9][0-9]):[0-5][0-9]$/, 'Formato invalido, debe ser HH:MM'),
    persons_on_board: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^([0-9][0-9][0-9])$/, 'Formato invalido, debe ser número de 3 digitos'),
    emergency_radio: Yup.array(),
    surveliance_equipment: Yup.array(),
    jackets: Yup.array(),
    dinghles: Yup.array(),
    dinghles_number: Yup.string().matches(/^$|^([0-9][0-9])$/, 'Formato invalido, debe ser número de 2 digitos'),
    dinghles_capacity: Yup.string().matches(/^$|^([0-9][0-9][0-9])$/, 'Formato invalido, debe ser número de 3 digitos'),
    dinghles_cover: Yup.array(),
    dinghles_color: Yup.string(),
    aircraft_colour_and_markings: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    remarks: Yup.string(),
    pilot_in_command: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    licence_number: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    address: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    aditional_services: Yup.array(),
    other_service: Yup.string()
}, [
    ['second_altn_aerodrome', 'second_altn_aerodrome']
]);

const FlightPlansForm: FC<FlightPlansFormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [aircrafts, setAircrafts] = useState([]);
    const [pilotProfile, setPilotProfile] = useState(null);
    const [aditionalServices, setAditionalServices] = useState([]);
    const [airports, setAirports] = useState([]);
    const [typeOfAircrafts, setTypeOfAircrafts] = useState([]);
    const { user } = useAuth();
    const [selectedAircraft, setSelectedAircraft] = useState(null);

    const defaultValues = {
        arrive_or_depart: data ? data.arrive_or_depart : '',
        company: data ? data.company : (selectedAircraft ? selectedAircraft.company : ''),
        operation_type: data ? data.operation_type : '',
        national_or_international: data ? data.national_or_international : '',
        aircraft_brand: data ? data.aircraft_brand : (selectedAircraft ? selectedAircraft.brand : ''),
        aircraft_model: data ? data.aircraft_model : (selectedAircraft ? selectedAircraft.model : ''),
        aircraft_plate: data ? data.aircraft_plate : (selectedAircraft ? selectedAircraft.plate : ''),
        operation_time: data ? data.operation_time : '',
        date: data ? moment(data.date.split("/").reverse().join("-")).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        aircraft_identification: data ? data.aircraft_identification : (selectedAircraft ? selectedAircraft.plate : ''),
        flight_rules: data ? data.flight_rules : '',
        type_of_flight: data ? data.type_of_flight : '',
        aircraft_number: data ? data.aircraft_number : '01',
        type_of_aircraft: data ? data.type_of_aircraft : (selectedAircraft ? selectedAircraft.type_of_aircraft : ''),
        wake_turbulence_cat: data ? data.wake_turbulence_cat : (selectedAircraft ? selectedAircraft.wake_turbulence_cat : ''),
        radiocomunication: data ? data.radiocomunication : (selectedAircraft ? selectedAircraft.radiocomunication : []),
        surveilance: data ? data.surveilance : (selectedAircraft ? selectedAircraft.surveilance : []),
        departure_aerodrome: data ? data.departure_aerodrome : '',
        departure_time: data ? data.departure_time : '',
        crusising_speed: data ? data.crusising_speed : (selectedAircraft ? selectedAircraft.crusising_speed : ''),
        level: data ? data.level : '',
        route: data ? data.route : '',
        destination_aerodrome: data ? data.destination_aerodrome : '',
        total_eet: data ? data.total_eet : '',
        altn_aerodrome: data ? data.altn_aerodrome : '',
        second_altn_aerodrome: data ? data.second_altn_aerodrome : '',
        other_information: data ? data.other_information : (selectedAircraft ? 'OPR/ ' + selectedAircraft.company : ''),
        endurance: data ? data.endurance : '',
        persons_on_board: data ? data.persons_on_board : '',
        emergency_radio: data ? data.emergency_radio : (selectedAircraft ? selectedAircraft.emergency_radio : []),
        surveliance_equipment: data ? data.surveliance_equipment : (selectedAircraft ? selectedAircraft.surveliance_equipment : []),
        jackets: data ? data.jackets : (selectedAircraft ? selectedAircraft.jackets : []),
        dinghles: data ? data.dinghles : (selectedAircraft ? selectedAircraft.dinghles : []),
        dinghles_number: data ? (data.dinghles_number ?? '') : (selectedAircraft ? (selectedAircraft.dinghles_number ?? '') : ''),
        dinghles_capacity: data ? (data.dinghles_capacity ?? '') : (selectedAircraft ? (selectedAircraft.dinghles_capacity ?? '') : ''),
        dinghles_cover: data ? data.dinghles_cover : (selectedAircraft ? selectedAircraft.dinghles_cover : []),
        dinghles_color: data ? (data.dinghles_color ?? '') : (selectedAircraft ? (selectedAircraft.dinghles_color ?? '') : ''),
        aircraft_colour_and_markings: data ? data.aircraft_colour_and_markings : (selectedAircraft ? selectedAircraft.aircraft_colour_and_markings : ''),
        remarks: data ? (data.remarks ?? '') : '',
        pilot_in_command: data ? data.pilot_in_command : (selectedAircraft ? pilotProfile.name : ''),
        licence_number: data ? data.licence_number : (selectedAircraft ? pilotProfile.licence_number : ''),
        address: data ? (data.address ?? '') : '',
        aditional_services: data ? data.aditional_services : [],
        other_service: data ? (data.other_service ?? '') : '',
        submit: null
    };
    const { handleSubmit, formState: { errors, isSubmitting }, setValue, trigger, control, reset, watch } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
        defaultValues
    });
    const arriveOrDepartWatch = watch('arrive_or_depart');

    useEffect(() => {
        if (user.can('flight-plans.create')) {
            http.get('api/get-flight-plans-form-info')
                .then(response => {
                    setAircrafts(response.data.aircrafts);
                    setAditionalServices(response.data.aditionalServices);
                    setAirports(response.data.airports);
                    setTypeOfAircrafts(response.data.typeOfAircrafts);
                    setPilotProfile(response.data.pilotProfile);
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' });
                });
        }
    }, []);

    const aircraftOnChange = (aircraftOption) => {
        http.get('api/pilot-aircrafts/' + aircraftOption.id)
            .then(response => {
                setSelectedAircraft(response.data);
            })
            .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    const checkboxArrayHandler = (fieldValue, e) => {
        let checkedBoxes = fieldValue;
        const checkValue = e.target.value;
        if (e.target.checked) {
            checkedBoxes.push(checkValue);
        } else {
            checkedBoxes = checkedBoxes.filter(checkedBox => checkedBox != checkValue);
        }
        return checkedBoxes;
    }

    useEffect(() => {
        reset(defaultValues);
    }, [data, selectedAircraft]);

    return (
        <>
            <form onSubmit={handleSubmit(async (formData) => {
                if (data) {
                    await http.put('api/flight-plans/' + data.id, formData)
                        .then((response: any) => {
                            onSuccessCallback();
                            enqueueSnackbar(t('FLIGHT-PLANS.EDITED'), { variant: 'success' });
                            reset();
                            setSelectedAircraft(null);
                        })
                        .catch((error) => {
                            console.error(error);
                            enqueueSnackbar(error.message, { variant: 'error' });
                        })
                } else {
                    http.post('api/flight-plans', formData)
                        .then((response: any) => {
                            onSuccessCallback()
                            enqueueSnackbar(t('FLIGHT-PLANS.SAVED'), { variant: 'success' });
                            reset();
                        })
                        .catch((error) => {
                            console.error(error);
                            enqueueSnackbar(error.message, { variant: 'error' });
                        })
                }
            })}>
                <DialogContent
                    dividers
                    sx={{
                        p: 3,
                    }}
                >
                    <Grid container spacing={3}>
                        {!data && <Grid item xs={12} md={12}>
                            <Autocomplete
                                options={aircrafts}
                                onChange={(_, option: any) => aircraftOnChange(option)}
                                isOptionEqualToValue={(option, current) => option.id === current.id}
                                renderInput={props => (
                                    <TextField
                                        {...props}
                                        label={t('FIELDS.AIRCRAFT')}
                                    />
                                )}
                            />
                        </Grid>}
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="arrive_or_depart"
                                control={control}
                                render={({ field, field: { onChange } }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.arrive_or_depart)}
                                        helperText={errors.arrive_or_depart?.message.toString()}
                                        label={t('FIELDS.ARRIVE_OR_DEPART')}
                                        variant="outlined"
                                        select
                                        {...field}
                                        onChange={(e) => {
                                            onChange(e);
                                            if (e.target.value == 'Llegada') {
                                                setValue('destination_aerodrome', 'XSCH')
                                                trigger('destination_aerodrome');
                                            }
                                        }}
                                    >
                                        <MenuItem value={'Llegada'}>Llegada</MenuItem>
                                        <MenuItem value={'Salida'}>Salida</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="company"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        name="company"
                                        fullWidth
                                        error={Boolean(errors.company)}
                                        helperText={errors.company?.message.toString()}
                                        label={t('FIELDS.COMPANY')}
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="operation_type"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        name="operation_type"
                                        fullWidth
                                        error={Boolean(errors.operation_type)}
                                        helperText={errors.operation_type?.message.toString()}
                                        label={t('FIELDS.OPERATION_TYPE')}
                                        variant="outlined"
                                        select
                                        {...field}
                                    >
                                        <MenuItem value={'Local'}>Local</MenuItem>
                                        <MenuItem value={'Foráneo'}>Foráneo</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="national_or_international"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.national_or_international)}
                                        helperText={errors.national_or_international?.message.toString()}
                                        label={t('FIELDS.NATIONAL_OR_INTERNATIONAL')}
                                        variant="outlined"
                                        select
                                        {...field}
                                    >
                                        <MenuItem value={'Nacional'}>Nacional</MenuItem>
                                        <MenuItem value={'Internacional'}>Internacional</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aircraft_brand"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.aircraft_brand)}
                                        helperText={errors.aircraft_brand?.message.toString()}
                                        label={t('FIELDS.AIRCRAFT_BRAND')}
                                        variant="outlined"
                                        {...field}
                                    ></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aircraft_model"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.aircraft_model)}
                                        helperText={errors.aircraft_model?.message.toString()}
                                        label={t('FIELDS.AIRCRAFT_MODEL')}
                                        variant="outlined"
                                        {...field}
                                    ></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aircraft_plate"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        name="aircraft_plate"
                                        fullWidth
                                        error={Boolean(errors.aircraft_plate)}
                                        helperText={errors.aircraft_plate?.message.toString()}
                                        label={t('FIELDS.AIRCRAFT_PLATE')}
                                        variant="outlined"
                                        {...field}
                                    ></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="operation_time"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.operation_time)}
                                        helperText={errors.operation_time?.message.toString()}
                                        fullWidth
                                        label={t("FIELDS.OPERATION_TIME")}
                                        variant="outlined"
                                        placeholder="HH:MM"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.date)}
                                        type={"date"}
                                        fullWidth
                                        helperText={errors.date?.message.toString()}
                                        label={t("FIELDS.DATE")}
                                        variant="outlined"
                                        {...field} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="aircraft_identification"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.aircraft_identification)}
                                        fullWidth
                                        helperText={errors.aircraft_identification?.message.toString()}
                                        label={t("FIELDS.AIRCRAFT_IDENTIFICATION")}
                                        name="aircraft_identification"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="flight_rules"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.flight_rules)}
                                        helperText={errors.flight_rules?.message.toString()}
                                        label={t('FIELDS.FLIGHT_RULES')}
                                        variant="outlined"
                                        select
                                        {...field}
                                    >
                                        <MenuItem value={'I'}>I</MenuItem>
                                        <MenuItem value={'V'}>V</MenuItem>
                                        <MenuItem value={'Y'}>Y</MenuItem>
                                        <MenuItem value={'Z'}>Z</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="type_of_flight"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.type_of_flight)}
                                        helperText={errors.type_of_flight?.message.toString()}
                                        label={t('FIELDS.TYPE_OF_FLIGHT')}
                                        variant="outlined"
                                        select
                                        {...field}
                                    >
                                        <MenuItem value={'S'}>S</MenuItem>
                                        <MenuItem value={'N'}>N</MenuItem>
                                        <MenuItem value={'G'}>G</MenuItem>
                                        <MenuItem value={'M'}>M</MenuItem>
                                        <MenuItem value={'X'}>X</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aircraft_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.aircraft_number)}
                                        fullWidth
                                        helperText={errors.aircraft_number?.message.toString()}
                                        label={t("FIELDS.AIRCRAFT_NUMBER")}
                                        name="aircraft_number"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="type_of_aircraft"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <Autocomplete
                                        options={typeOfAircrafts}
                                        freeSolo={true}
                                        onChange={(_, option: any) => setValue('type_of_aircraft', option ?? '')}
                                        value={value}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.TYPE_OF_AIRCRAFT')}
                                                error={Boolean(errors.type_of_aircraft)}
                                                helperText={errors.type_of_aircraft?.message.toString()}
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="wake_turbulence_cat"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.wake_turbulence_cat)}
                                        helperText={errors.wake_turbulence_cat?.message.toString()}
                                        label={t('FIELDS.WAKE_TURBULENCE_CAT')}
                                        variant="outlined"
                                        select
                                        {...field}
                                    >
                                        <MenuItem value={'H'}>H</MenuItem>
                                        <MenuItem value={'M'}>M</MenuItem>
                                        <MenuItem value={'L'}>L</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="radiocomunication"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.radiocomunication)}
                                        helperText={errors.radiocomunication?.message.toString()}
                                        label={t('FIELDS.RADIOCOMUNICATION')}
                                        variant="outlined"
                                        select
                                        SelectProps={{ multiple: true }}
                                        {...field}
                                    >
                                        <MenuItem value={'N'}>N</MenuItem>
                                        <MenuItem value={'S'}>S</MenuItem>
                                        <MenuItem value={'A'}>A</MenuItem>
                                        <MenuItem value={'B'}>B</MenuItem>
                                        <MenuItem value={'C'}>C</MenuItem>
                                        <MenuItem value={'D'}>D</MenuItem>
                                        <MenuItem value={'E1'}>E1</MenuItem>
                                        <MenuItem value={'E2'}>E2</MenuItem>
                                        <MenuItem value={'E3'}>E3</MenuItem>
                                        <MenuItem value={'F'}>F</MenuItem>
                                        <MenuItem value={'G'}>G</MenuItem>
                                        <MenuItem value={'H'}>H</MenuItem>
                                        <MenuItem value={'I'}>I</MenuItem>
                                        <MenuItem value={'J1'}>J1</MenuItem>
                                        <MenuItem value={'J2'}>J2</MenuItem>
                                        <MenuItem value={'J3'}>J3</MenuItem>
                                        <MenuItem value={'J4'}>J4</MenuItem>
                                        <MenuItem value={'J5'}>J5</MenuItem>
                                        <MenuItem value={'J6'}>J6</MenuItem>
                                        <MenuItem value={'J7'}>J7</MenuItem>
                                        <MenuItem value={'K'}>K</MenuItem>
                                        <MenuItem value={'L'}>L</MenuItem>
                                        <MenuItem value={'M1'}>M1</MenuItem>
                                        <MenuItem value={'M2'}>M2</MenuItem>
                                        <MenuItem value={'M3'}>M3</MenuItem>
                                        <MenuItem value={'O'}>O</MenuItem>
                                        <MenuItem value={'P1-P9'}>P1-P9</MenuItem>
                                        <MenuItem value={'R'}>R</MenuItem>
                                        <MenuItem value={'T'}>T</MenuItem>
                                        <MenuItem value={'U'}>U</MenuItem>
                                        <MenuItem value={'V'}>V</MenuItem>
                                        <MenuItem value={'W'}>W</MenuItem>
                                        <MenuItem value={'X'}>X</MenuItem>
                                        <MenuItem value={'Y'}>Y</MenuItem>
                                        <MenuItem value={'Z'}>Z</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="surveilance"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors.surveilance)}
                                        helperText={errors.surveilance?.message.toString()}
                                        label={t('FIELDS.SURVEILANCE')}
                                        variant="outlined"
                                        select
                                        SelectProps={{ multiple: true }}
                                        {...field}
                                    >
                                        <MenuItem value={'N'}>N</MenuItem>
                                        <ListSubheader>SSR en Modos A y C</ListSubheader>
                                        <MenuItem value={'A'}>A</MenuItem>
                                        <MenuItem value={'C'}>C</MenuItem>
                                        <ListSubheader>SSR modo S</ListSubheader>
                                        <MenuItem value={'E'}>E</MenuItem>
                                        <MenuItem value={'H'}>H</MenuItem>
                                        <MenuItem value={'I'}>I</MenuItem>
                                        <MenuItem value={'L'}>L</MenuItem>
                                        <MenuItem value={'P'}>P</MenuItem>
                                        <MenuItem value={'S'}>S</MenuItem>
                                        <MenuItem value={'X'}>X</MenuItem>
                                        <ListSubheader>ADS-B</ListSubheader>
                                        <MenuItem value={'B1'}>B1</MenuItem>
                                        <MenuItem value={'B2'}>B2</MenuItem>
                                        <MenuItem value={'U1'}>U1</MenuItem>
                                        <MenuItem value={'U2'}>U2</MenuItem>
                                        <MenuItem value={'V1'}>V1</MenuItem>
                                        <MenuItem value={'V2'}>V2</MenuItem>
                                        <ListSubheader>ADS-C</ListSubheader>
                                        <MenuItem value={'D1'}>D1</MenuItem>
                                        <MenuItem value={'G1'}>G1</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="departure_aerodrome"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <Autocomplete
                                        options={airports}
                                        freeSolo={true}
                                        onChange={(_, option: any) => setValue('departure_aerodrome', option ?? '')}
                                        value={value}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.DEPARTURE_AERODROME')}
                                                error={Boolean(errors.departure_aerodrome)}
                                                helperText={errors.departure_aerodrome?.message.toString()}
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="departure_time"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.departure_time)}
                                        fullWidth
                                        helperText={errors.departure_time?.message.toString()}
                                        label={t("FIELDS.DEPARTURE_TIME")}
                                        name="departure_time"
                                        variant="outlined"
                                        placeholder="HH:MM"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="crusising_speed"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.crusising_speed)}
                                        fullWidth
                                        helperText={errors.crusising_speed?.message.toString()}
                                        label={t("FIELDS.CRUSISING_SPEED")}
                                        name="crusising_speed"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="level"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.level)}
                                        fullWidth
                                        helperText={errors.level?.message.toString()}
                                        label={t("FIELDS.LEVEL")}
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                name="route"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.route)}
                                        fullWidth
                                        helperText={errors.route?.message.toString()}
                                        label={t("FIELDS.ROUTE")}
                                        name="route"
                                        variant="outlined"
                                        multiline={true}
                                        rows={5}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="destination_aerodrome"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <Autocomplete
                                        options={airports}
                                        freeSolo={true}
                                        onChange={(_, option: any) => setValue('destination_aerodrome', option ?? '')}
                                        value={value}
                                        readOnly={arriveOrDepartWatch == 'Llegada'}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.DESTINATION_AERODROME')}
                                                error={Boolean(errors.destination_aerodrome)}
                                                helperText={errors.destination_aerodrome?.message.toString()}
                                                name={'destination_aerodrome'}
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="total_eet"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.total_eet)}
                                        fullWidth
                                        helperText={errors.total_eet?.message.toString()}
                                        label={t("FIELDS.TOTAL_EET")}
                                        variant="outlined"
                                        placeholder="HH:MM"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="altn_aerodrome"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <Autocomplete
                                        options={airports}
                                        freeSolo={true}
                                        onChange={(_, option: any) => setValue('altn_aerodrome', option ?? '')}
                                        value={value}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.ALTN_AERODROME')}
                                                error={Boolean(errors.altn_aerodrome)}
                                                helperText={errors.altn_aerodrome?.message.toString()}
                                                name={'altn_aerodrome'}
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="second_altn_aerodrome"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <Autocomplete
                                        options={airports}
                                        freeSolo={true}
                                        onChange={(_, option: any) => setValue('second_altn_aerodrome', option ?? '')}
                                        value={value}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.SECOND_ALTN_AERODROME')}
                                                error={Boolean(errors.second_altn_aerodrome)}
                                                helperText={errors.second_altn_aerodrome?.message.toString()}
                                                name={'second_altn_aerodrome'}
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                name="other_information"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.other_information)}
                                        fullWidth
                                        helperText={errors.other_information?.message.toString()}
                                        label={t("FIELDS.OTHER_INFORMATION")}
                                        name="other_information"
                                        variant="outlined"
                                        multiline={true}
                                        rows={5}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="endurance"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.endurance)}
                                        fullWidth
                                        helperText={errors.endurance?.message.toString()}
                                        label={t("FIELDS.ENDURANCE")}
                                        name="endurance"
                                        variant="outlined"
                                        placeholder="HH:MM"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="persons_on_board"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.persons_on_board)}
                                        fullWidth
                                        helperText={errors.persons_on_board?.message.toString()}
                                        label={t("FIELDS.PERSONS_ON_BOARD")}
                                        name="persons_on_board"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormGroup>
                                <FormLabel component="legend">{t("FIELDS.EMERGENCY_RADIO")}</FormLabel>
                                <Box display={'flex'} justifyContent={'space-evenly'}>
                                    <Controller
                                        name="emergency_radio"
                                        control={control}
                                        render={({ field: { onChange, value, ...field } }) => (
                                            <Fragment>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('U')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />}
                                                            size="small"
                                                            value={'U'}
                                                            {...field} />
                                                    }
                                                    label={'U'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('V')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'V'} />
                                                    }
                                                    label={'V'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('E')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'E'} />
                                                    }
                                                    label={'E'}
                                                />
                                            </Fragment>
                                        )}
                                    />
                                </Box>
                            </FormGroup>
                            {Boolean(errors.emergency_radio) && <FormHelperText error={true}>{errors.emergency_radio?.message.toString()}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormLabel component="legend">{t("FIELDS.SURVELIANCE_EQUIPMENT")}</FormLabel>
                                <Box display={'flex'} justifyContent={'space-evenly'}>
                                    <Controller
                                        name="surveliance_equipment"
                                        control={control}
                                        render={({ field: { onChange, value, ...field } }) => (
                                            <Fragment>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('S')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'S'} />
                                                    }
                                                    label={'S'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('P')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'P'} />
                                                    }
                                                    label={'P'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('D')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'D'} />
                                                    }
                                                    label={'D'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('M')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'M'} />
                                                    }
                                                    label={'M'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('J')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'J'} />
                                                    }
                                                    label={'J'}
                                                />
                                            </Fragment>
                                        )}
                                    />
                                </Box>
                            </FormGroup>
                            {Boolean(errors.surveliance_equipment) && <FormHelperText error={true}>{errors.surveliance_equipment?.message.toString()}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormLabel component="legend">{t("FIELDS.JACKETS")}</FormLabel>
                                <Box display={'flex'} justifyContent={'space-evenly'}>
                                    <Controller
                                        name="jackets"
                                        control={control}
                                        render={({ field: { onChange, value, ...field } }) => (
                                            <Fragment>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('J')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'J'} />
                                                    }
                                                    label={'J'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('L')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'L'} />
                                                    }
                                                    label={'L'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('F')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'F'} />
                                                    }
                                                    label={'F'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('U')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'U'} />
                                                    }
                                                    label={'U'}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={value.includes('V')}
                                                            onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                            checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'V'} />
                                                    }
                                                    label={'V'}
                                                />
                                            </Fragment>
                                        )}
                                    />
                                </Box>
                            </FormGroup>
                            {Boolean(errors.jackets) && <FormHelperText error={true}>{errors.jackets?.message.toString()}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormLabel component="legend">{t("FIELDS.DINGHLES")}</FormLabel>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Controller
                                name="dinghles"
                                control={control}
                                render={({ field: { onChange, value, ...field } }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value.includes('D')}
                                                onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                checkedIcon={<DisabledByDefaultIcon />} size="small" {...field} value={'D'} />
                                        }
                                        label={'D'}
                                    />
                                )}
                            />
                            {Boolean(errors.dinghles) && <FormHelperText error={true}>{errors.dinghles?.message.toString()}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="dinghles_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.dinghles_number)}
                                        fullWidth
                                        helperText={errors.dinghles_number?.message.toString()}
                                        label={t("FIELDS.DINGHLES_NUMBER")}
                                        name="dinghles_number"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="dinghles_capacity"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.dinghles_capacity)}
                                        fullWidth
                                        helperText={errors.dinghles_capacity?.message.toString()}
                                        label={t("FIELDS.DINGHLES_CAPACITY")}
                                        name="dinghles_capacity"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Controller
                                name="dinghles_cover"
                                control={control}
                                render={({ field: { onChange, value, ...field } }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value.includes('C')}
                                                onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                checkedIcon={<DisabledByDefaultIcon />} name="dinghles_cover" {...field} value={'C'} />
                                        }
                                        label={'C'}
                                    />
                                )}
                            />
                            {Boolean(errors.dinghles_cover) && <FormHelperText error={true}>{errors.dinghles_cover?.message.toString()}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="dinghles_color"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.dinghles_color)}
                                        fullWidth
                                        helperText={errors.dinghles_color?.message.toString()}
                                        label={t("FIELDS.DINGHLES_COLOR")}
                                        name="dinghles_color"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                name="aircraft_colour_and_markings"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.aircraft_colour_and_markings)}
                                        fullWidth
                                        helperText={errors.aircraft_colour_and_markings?.message.toString()}
                                        label={t("FIELDS.AIRCRAFT_COLOUR_AND_MARKINGS")}
                                        name="aircraft_colour_and_markings"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                name="remarks"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.remarks)}
                                        fullWidth
                                        helperText={errors.remarks?.message.toString()}
                                        label={t("FIELDS.REMARKS")}
                                        name="remarks"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="pilot_in_command"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.pilot_in_command)}
                                        fullWidth
                                        helperText={errors.pilot_in_command?.message.toString()}
                                        label={t("FIELDS.PILOT_IN_COMMAND")}
                                        name="pilot_in_command"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="licence_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.licence_number)}
                                        fullWidth
                                        helperText={errors.licence_number?.message.toString()}
                                        label={t("FIELDS.LICENCE_NUMBER")}
                                        name="licence_number"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={Boolean(errors.address)}
                                        fullWidth
                                        helperText={errors.address?.message.toString()}
                                        label={t("FIELDS.ADDRESS")}
                                        name="address"
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormGroup>
                                <FormLabel component="legend">{t("FIELDS.ADITIONAL_SERVICES")}</FormLabel>
                                <Grid container>
                                    <Controller
                                        name="aditional_services"
                                        control={control}
                                        render={({ field: { onChange, value, ...field } }) => (
                                            <Fragment>
                                                {
                                                    aditionalServices.map(aditionalService =>
                                                        <Grid item xs={12} md={3} key={aditionalService}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value.includes(aditionalService)}
                                                                        onChange={(e) => onChange(checkboxArrayHandler(value, e))}
                                                                        size="small" value={aditionalService} />
                                                                }
                                                                label={aditionalService}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                            </Fragment>
                                        )}
                                    />
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="other_service"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    error={Boolean(errors.other_service)}
                                                    fullWidth
                                                    helperText={errors.other_service?.message.toString()}
                                                    label={t("FIELDS.OTHER_SERVICE")}
                                                    name="other_service"
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            {Boolean(errors.other_service) && <FormHelperText error={true}>{errors.other_service?.message.toString()}</FormHelperText>}
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions
                    sx={{
                        p: 3,
                    }}
                >
                    <Button color="secondary" onClick={modalCloseCallback}>
                        {t("GENERAL.CANCEL")}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={
                            isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={Boolean(errors.submit) || isSubmitting}
                        variant="contained"
                    >
                        {t(!data ? "FLIGHT-PLANS.CREATE" : 'FLIGHT-PLANS.EDIT')}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

export default FlightPlansForm;