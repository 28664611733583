import PageHeader from "src/components/PageHeader";
import { useTranslation } from "react-i18next";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import GeneralFlightPlanForm from "./GeneralFlightPlanForm";

function GeneralFlightPlan() {
    const { t } = useTranslation();

    return (
        <>
            <PageHeader
                heading={t(("GENERAL-FLIGHT-PLAN.HEADING").toUpperCase())}
                subheading={t(("GENERAL-FLIGHT-PLAN.SUBHEADING").toUpperCase())}
                icon={<DocumentScannerIcon fontSize="large" />}
            />
            <GeneralFlightPlanForm />
        </>
    );
}

export default GeneralFlightPlan;
