import { FC } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import moment from "moment";
import anyToString from "src/utils/anyToString";

interface NotamsFormProps {
    modalCloseCallback,
    onSuccessCallback,
    data?: any,
    extra?: any
}

const NotamsForm: FC<NotamsFormProps> = ({ modalCloseCallback, onSuccessCallback, data, extra }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Formik
                initialValues={{
                    message: data ? data.message : "",
                    init_date: data ? data.init_date.split('/').reverse().join('-') : moment().format('YYYY-MM-DD'),
                    end_date: data ? data.end_date.split('/').reverse().join('-') : moment().format('YYYY-MM-DD'),
                    severity: data ? data.severity : "info",
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    message: Yup.string()
                        .required(t("VALIDATION.REQUIRED"))
                        .min(1, t('VALIDATION.MIN_ONE')),
                    init_date: Yup.date()
                        .required(t("VALIDATION.REQUIRED")),
                    end_date: Yup.date()
                        .required(t("VALIDATION.REQUIRED")),
                    severity: Yup.mixed()
                        .required(t("VALIDATION.REQUIRED"))
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        http.put('api/notams/' + data.id, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.data.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        http.post('api/notams', _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback()
                                enqueueSnackbar(response.data.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.message && errors.message)}
                                        type={'text'}
                                        fullWidth
                                        helperText={touched.message && errors.message && errors.message.toString()}
                                        label={t("FIELDS.MESSAGE")}
                                        name="message"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.message}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.init_date && errors.init_date)}
                                        type={"date"}
                                        fullWidth
                                        helperText={touched.init_date && errors.init_date && errors.init_date.toString()}
                                        label={t("FIELDS.INIT_DATE")}
                                        name="init_date"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.init_date}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.end_date && errors.end_date)}
                                        type={"date"}
                                        fullWidth
                                        helperText={touched.end_date && errors.end_date && errors.end_date.toString()}
                                        label={t("FIELDS.END_DATE")}
                                        name="end_date"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.end_date}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        name="severity"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.severity}
                                        error={Boolean(touched.severity && errors.severity)}
                                        variant="outlined"
                                        label={t("FIELDS.SEVERITY")}
                                        select
                                        helperText={touched.severity && errors.severity && errors.severity.toString()}
                                    >
                                        <MenuItem value={'info'}>{t('NOTAMS.SEVERITY.info')}</MenuItem>
                                        <MenuItem value={'success'}>{t('NOTAMS.SEVERITY.success')}</MenuItem>
                                        <MenuItem value={'warning'}>{t('NOTAMS.SEVERITY.warning')}</MenuItem>
                                        <MenuItem value={'error'}>{t('NOTAMS.SEVERITY.error')}</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                            }}
                        >
                            <Button color="secondary" onClick={modalCloseCallback}>
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={Boolean(errors.submit) || isSubmitting}
                                variant="contained"
                            >
                                {t(!data ? "NOTAMS.CREATE" : 'NOTAMS.EDIT')}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default NotamsForm;