import {
  Box,
  styled,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 52px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 52px;
`
);

function Logo() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <LogoWrapper to="/">
      <LogoSignWrapper>
        <img src="/logo.png" alt="" width={"100%"} />
      </LogoSignWrapper>
    </LogoWrapper>
  );
}

export default Logo;
