import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
    Box,
    Card,
    Typography,
    Container,
    Button,
    styled,
    CircularProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import http from 'src/utils/httpHelper';
import { useSnackbar } from 'notistack';
import MailLockIcon from '@mui/icons-material/MailLock';
import useAuth from 'src/hooks/useAuth';
import Grow from '@mui/material/Grow';

const MainContent = styled(Box)(
    () => `
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    `
);

function EmailVerificationRequired() {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const requestVerificationEmail = () => {
        http.post('email/verification-notification')
            .then((response: any) => {
                setSuccessMessage(true);
            })
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
                console.error(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }
    const { user } = useAuth();

    if (user.email_verified_at) {
        return <Navigate to="/" />
    }

    return (

        <>
            <Helmet>
                <title>{t('EMAIL_VERIFICATION.HEADING')}</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="sm">
                    <Card
                        sx={{
                            mt: 3,
                            p: 4
                        }}
                    >
                        <Box sx={{ pb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MailLockIcon style={{ zoom: 8 }} />
                        </Box>
                        <Box>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: 1
                                }}
                            >
                                {t('EMAIL_VERIFICATION.HEADING')}
                            </Typography>
                            <Typography
                                variant="h4"
                                color="text.secondary"
                                fontWeight="normal"
                                sx={{
                                    mb: 3
                                }}
                            >
                                {t('EMAIL_VERIFICATION.SUBHEADING')}
                            </Typography>
                        </Box>

                        {
                            !successMessage &&
                            <Button
                                onClick={requestVerificationEmail}
                                sx={{
                                    mt: 3
                                }}
                                color="primary"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={isSubmitting}
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                            >
                                {t('EMAIL_VERIFICATION.BUTTON')}
                            </Button>
                        }
                        {
                            successMessage &&
                            <Grow in timeout={750}>
                                <Typography
                                    align='center'
                                    color={'success.main'}
                                    sx={{
                                        py: 2
                                    }}
                                    variant="h3"
                                >
                                    {t('EMAIL_VERIFICATION.SUCCESS')}
                                </Typography>
                            </Grow>
                        }
                    </Card>
                </Container>
            </MainContent>
        </>
    );
}

export default EmailVerificationRequired;
