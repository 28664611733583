import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  styled,
  Typography,
  alpha,
  lighten,
  Avatar,
  Box,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "../PageTitleWrapper";

interface PageHeaderProps {
  heading: string;
  subheading: string;
  icon: any;
  actions?: any;
  children?: React.ReactNode;
}

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${theme.palette.mode === 'dark'
      ? theme.colors.alpha.trueWhite[10]
      : theme.colors.alpha.white[50]
    };
      box-shadow: ${theme.palette.mode === 'dark'
      ? '0 1px 0 ' +
      alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
      ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
      : '0px 2px 4px -3px ' +
      alpha(theme.colors.alpha.black[100], 0.4) +
      ', 0px 5px 16px -4px ' +
      alpha(theme.colors.alpha.black[100], 0.2)
    };
`
);

const PageHeader: FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <PageTitleWrapper>
        <Helmet>
          <title>{props.heading}</title>
        </Helmet>
        <Box
          display="flex"
          alignItems={{ xs: 'stretch', md: 'center' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <AvatarPageTitle variant="rounded">
              {props.icon}
            </AvatarPageTitle>
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {props.heading}
              </Typography>
              <Typography variant="subtitle2">
                {props.subheading}
              </Typography>
            </Box>
          </Box>
          {props.actions}
        </Box>
      </PageTitleWrapper>
    </>
  );
};

export default PageHeader;
