import { Link as RouterLink, Navigate } from "react-router-dom";
import { Box, Card, Link, Typography, Container, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import Logo from "src/components/LogoSign";
import useAuth from "src/hooks/useAuth";

const BottomWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};
    display: flex;
    align-items: center;
    justify-content: center;
`
);

const MainContent = styled(Box)(
  () => `
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  `
);

function LoginBasic() {
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return (
      <Navigate to={'/admin/dashboard'} replace></Navigate>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>{t('LOGIN.HEADING')}</title>
        </Helmet>
        <MainContent>
          <Container maxWidth="sm">
            <Card
              sx={{
                p: 4
              }}
            >
              <Box sx={{ pb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/logo.png" alt="" width={"50%"} />
              </Box>
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                  }}
                >
                  {t('LOGIN.HEADING')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3,
                  }}
                >
                  {t("LOGIN.SUBHEADING")}
                </Typography>
              </Box>
              <LoginForm />
              <Box mt={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t("LOGIN.DONT_YOU_HAVE_AN_ACCOUNT")}
                </Typography>{" "}
                <Link component={RouterLink} to="/register">
                  <b>{t("LOGIN.REGISTER")}</b>
                </Link>
              </Box>
            </Card>
            <BottomWrapper></BottomWrapper>
          </Container>
        </MainContent>
      </>
    );
  }
}

export default LoginBasic;
