import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography, Zoom as ZoomTransition } from "@mui/material";
import { useTranslation } from "react-i18next";
import CrudBase from "src/components/CrudBase";
import { forwardRef, Fragment, ReactElement, Ref, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import FlightPlansForm from "./FlightPlansForm";
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import FlightPlanPdfRender from "./FlightPlanPdfRender";
import useAuth from "src/hooks/useAuth";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Box } from "@mui/system";
import Text from "src/components/Text";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { TransitionProps } from "@mui/material/transitions";
import styled from "@emotion/styled";

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <ZoomTransition itemRef="input" ref={ref} {...props} />;
});

const ScaledOnHoverIconButton = styled(IconButton)(
    ({ theme }) => `
      &:hover {
        svg {
          transform: scale(1.5);
        }
      }
    `
)

export function FlightPlans() {
    const { t } = useTranslation();
    const clickedExtraActionButton = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [pdfOpen, setPdfOpen] = useState(false);
    const [pilotInfoOpen, setPilotInfoOpen] = useState(false);
    const [authorizeRejectModal, setAuthorizeRejectModal] = useState(false);
    const [authorizeRejectModalMode, setAuthorizeRejectModalMode] = useState('');
    const [authComment, setAuthComment] = useState('');
    const [aircraftInfoOpen, setAircraftInfoOpen] = useState(false);
    const [selectedItemData, setSelectedItemData] = useState(null);
    const indexCallback = useRef(null);
    const resource = 'flight-plans';

    const modalPdfOpen = (item, ref) => {
        clickedExtraActionButton.current = ref;
        setSelectedItemData(item);
        setPdfOpen(true);
    };
    const modalPdfClose = () => {
        setPdfOpen(false);
    };

    const modalPilotInfoOpen = (item, ref) => {
        clickedExtraActionButton.current = ref;
        setSelectedItemData(item);
        setPilotInfoOpen(true);
    };
    const modalPilotInfoClose = () => {
        setPilotInfoOpen(false);
    };

    const modalAircraftInfoOpen = (item, ref) => {
        clickedExtraActionButton.current = ref;
        setSelectedItemData(item);
        setAircraftInfoOpen(true);
    };
    const modalAircraftInfoClose = () => {
        setAircraftInfoOpen(false);
    };

    const authorizeRejectModalOpen = (mode: 'authorize' | 'reject', item, ref, callback) => {
        clickedExtraActionButton.current = ref;
        setAuthorizeRejectModal(true);
        setAuthorizeRejectModalMode(mode);
        setSelectedItemData(item);
        indexCallback.current = callback;
    };
    const authorizeRejectModalClose = () => {
        setAuthorizeRejectModal(false);
        setAuthComment('');
    };

    const authorizeFlightPlan = () => {
        http.post('api/flight-plans/' + selectedItemData.id + '/authorize', { comment: authComment })
            .then(response => {
                enqueueSnackbar(response.data.message, { variant: 'success' })
                authorizeRejectModalClose();
                indexCallback.current();
            })
            .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    const rejectFlightPlan = () => {
        http.post('api/flight-plans/' + selectedItemData.id + '/reject', { comment: authComment })
            .then(response => {
                enqueueSnackbar(response.data.message, { variant: 'success' });
                authorizeRejectModalClose();
                indexCallback.current();
            })
            .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }

    const ExtraActions = (item, callback) => {
        return (
            <Fragment>
                <Tooltip title={t((resource + '.PILOT').toUpperCase())} arrow>
                    <ScaledOnHoverIconButton
                        color="info"
                        onClick={(ref) => modalPilotInfoOpen(item, ref)}
                    >
                        <AccountBoxIcon fontSize="small" />
                    </ScaledOnHoverIconButton>
                </Tooltip>
                <Tooltip title={t((resource + '.AIRCRAFT').toUpperCase())} arrow>
                    <ScaledOnHoverIconButton
                        color="info"
                        onClick={(ref) => modalAircraftInfoOpen(item, ref)}
                    >
                        <AirplanemodeActiveIcon fontSize="small" />
                    </ScaledOnHoverIconButton>
                </Tooltip>
                {
                    (user.can('flight-plans.authorize-reject') || item.status == 'Autorizado') && <Tooltip title={t((resource + '.PDF').toUpperCase())} arrow>
                        <ScaledOnHoverIconButton
                            color="error"
                            onClick={(ref) => modalPdfOpen(item, ref)}
                        >
                            <PictureAsPdfIcon fontSize="small" />
                        </ScaledOnHoverIconButton>
                    </Tooltip>
                }
                {
                    item.status == 'Pendiente de revisión' && user.can('flight-plans.authorize-reject') ?
                        <>
                            <Tooltip title={t((resource + '.AUTHORIZE').toUpperCase())} arrow>
                                <ScaledOnHoverIconButton
                                    color="success"
                                    onClick={(ref) => authorizeRejectModalOpen('authorize', item, ref, callback)}
                                >
                                    <CheckCircleIcon fontSize="small" />
                                </ScaledOnHoverIconButton>
                            </Tooltip>
                            <Tooltip title={t((resource + '.REJECT').toUpperCase())} arrow>
                                <ScaledOnHoverIconButton
                                    color="error"
                                    onClick={(ref) => authorizeRejectModalOpen('reject', item, ref, callback)}
                                >
                                    <CancelIcon fontSize="small" />
                                </ScaledOnHoverIconButton>
                            </Tooltip>
                        </>
                        : null
                }
            </Fragment>
        )
    }
    return (
        <Fragment>
            <CrudBase
                resource="flight-plans"
                indexColumns={['auth_number', 'date', 'pilot_in_command', 'licence_number', 'status']}
                showModalHiddenColumns={['pilot_profile', 'aircraftInfo']}
                icon={<AirplaneTicketIcon fontSize="large" />}
                form={FlightPlansForm}
                editButton={(user, item) => user.can(resource + '.edit') && item?.status == 'Pendiente de revisión'}
                deleteButton={(user, item) => user.can(resource + '.delete') && item?.status == 'Pendiente de revisión'}
                customColumns={{
                    emergency_radio: (emergency_radio) => ['U', 'V', 'E'].filter(v => !emergency_radio.includes(v)),
                    surveliance_equipment: (surveliance_equipment) => ['S', 'P', 'D', 'M', 'J'].filter(v => !surveliance_equipment.includes(v)),
                    jackets: (jackets) => ['J', 'L', 'F', 'U', 'V'].filter(v => !jackets.includes(v)),
                    dinghles: (dinghles) => dinghles.length ? '' : 'D',
                    dinghles_cover: (dinghles_cover) => dinghles_cover.length ? '' : 'C'
                }}
                searchText={true}
                extraActions={ExtraActions} />

            <Dialog
                fullWidth
                maxWidth="md"
                open={pdfOpen}
                onClose={modalPdfClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    style: {
                        transformOrigin: clickedExtraActionButton.current?.clientX + 'px ' + clickedExtraActionButton.current?.clientY + 'px 0'
                    },
                    timeout: 250
                }}
            >
                <DialogContent>
                    <FlightPlanPdfRender item={selectedItemData} />
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="md"
                open={pilotInfoOpen}
                onClose={modalPilotInfoClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    style: {
                        transformOrigin: clickedExtraActionButton.current?.clientX + 'px ' + clickedExtraActionButton.current?.clientY + 'px 0'
                    },
                    timeout: 250
                }}
            >
                <DialogTitle
                    sx={{
                        p: 3,
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t((resource + '.PILOT_INFO').toUpperCase())}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t((resource + '.PILOT_INFO_DESC').toUpperCase())}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle2">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.NAME')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{selectedItemData?.pilot_profile.name}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.CELLPHONE')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{selectedItemData?.pilot_profile.cellphone}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.LICENCE_NUMBER')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{selectedItemData?.pilot_profile.licence_number}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.OPERATION_BASE')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{selectedItemData?.pilot_profile.operation_base}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.ADDRESS')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{selectedItemData?.pilot_profile.address}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} md={6} padding={1}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_LICENCE_PHOTO')}
                                    &nbsp;
                                    {
                                        selectedItemData?.pilot_profile?.pilot_licence_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_licence_photo_base_64?.mime != 'application/pdf' &&
                                        <a href={selectedItemData?.pilot_profile?.pilot_licence_photo_base_64?.content} download>{t('GENERAL.DOWNLOAD')}</a>
                                    }
                                </Text>
                                {
                                    selectedItemData?.pilot_profile?.pilot_licence_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_licence_photo_base_64?.mime != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={selectedItemData?.pilot_profile?.pilot_licence_photo_base_64?.content}></img>
                                        </Zoom>
                                        :
                                        <embed src={selectedItemData?.pilot_profile?.pilot_licence_photo_base_64?.content} width="100%" style={{ minHeight: '400px' }} />
                                }
                            </Grid>
                            <Grid item xs={12} md={6} padding={1}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_LICENCE_REVERSE_PHOTO')}
                                    &nbsp;
                                    {
                                        selectedItemData?.pilot_profile?.pilot_licence_reverse_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_licence_reverse_photo_base_64?.mime != 'application/pdf' &&
                                        <a href={selectedItemData?.pilot_profile?.pilot_licence_reverse_photo_base_64?.content} download>{t('GENERAL.DOWNLOAD')}</a>
                                    }
                                </Text>
                                {
                                    selectedItemData?.pilot_profile?.pilot_licence_reverse_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_licence_reverse_photo_base_64?.mime != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={selectedItemData?.pilot_profile?.pilot_licence_reverse_photo_base_64?.content}></img>
                                        </Zoom>
                                        :
                                        <embed src={selectedItemData?.pilot_profile?.pilot_licence_reverse_photo_base_64?.content} width="100%" style={{ minHeight: '400px' }} />
                                }
                            </Grid>
                            <Grid item xs={12} md={6} padding={1}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_USA_LICENCE_PHOTO')}
                                    &nbsp;
                                    {
                                        selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64?.mime != 'application/pdf' &&
                                        <a href={selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64?.content} download>{t('GENERAL.DOWNLOAD')}</a>
                                    }
                                </Text>
                                {
                                    selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64?.mime != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64?.content}></img>
                                        </Zoom>
                                        :
                                        <embed src={selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64?.content} width="100%" style={{ minHeight: '400px' }} />
                                }
                            </Grid>
                            <Grid item xs={12} md={6} padding={1}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_USA_LICENCE_REVERSE_PHOTO')}
                                    &nbsp;
                                    {
                                        selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64?.mime != 'application/pdf' &&
                                        <a href={selectedItemData?.pilot_profile?.pilot_usa_licence_photo_base_64?.content} download>{t('GENERAL.DOWNLOAD')}</a>
                                    }
                                </Text>
                                {
                                    selectedItemData?.pilot_profile?.pilot_usa_licence_reverse_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.pilot_usa_licence_reverse_photo_base_64?.mime != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={selectedItemData?.pilot_profile?.pilot_usa_licence_reverse_photo_base_64?.content}></img>
                                        </Zoom>
                                        :
                                        <embed src={selectedItemData?.pilot_profile?.pilot_usa_licence_reverse_photo_base_64?.content} width="100%" style={{ minHeight: '400px' }} />
                                }
                            </Grid>
                            <Grid item xs={12} md={6} padding={1}>
                                <Text color="black">
                                    {t('FIELDS.INE_PHOTO')}
                                    &nbsp;
                                    {
                                        selectedItemData?.pilot_profile?.ine_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.ine_photo_base_64?.mime != 'application/pdf' &&
                                        <a href={selectedItemData?.pilot_profile?.ine_photo_base_64?.content} download>{t('GENERAL.DOWNLOAD')}</a>
                                    }
                                </Text>
                                {
                                    selectedItemData?.pilot_profile?.ine_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.ine_photo_base_64?.mime != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={selectedItemData?.pilot_profile?.ine_photo_base_64?.content}></img>
                                        </Zoom>
                                        :
                                        <embed src={selectedItemData?.pilot_profile?.ine_photo_base_64?.content} width="100%" style={{ minHeight: '400px' }} />
                                }
                            </Grid>
                            <Grid item xs={12} md={6} padding={1}>
                                <Text color="black">
                                    {t('FIELDS.HEALTH_TEST_PHOTO')}
                                    &nbsp;
                                    {
                                        selectedItemData?.pilot_profile?.health_test_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.health_test_photo_base_64?.mime != 'application/pdf' &&
                                        <a href={selectedItemData?.pilot_profile?.health_test_photo_base_64?.content} download>{t('GENERAL.DOWNLOAD')}</a>
                                    }
                                </Text>
                                {
                                    selectedItemData?.pilot_profile?.health_test_photo_base_64 &&
                                        selectedItemData?.pilot_profile?.health_test_photo_base_64?.mime != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={selectedItemData?.pilot_profile?.health_test_photo_base_64?.content}></img>
                                        </Zoom>
                                        :
                                        <Zoom>
                                            <embed src={selectedItemData?.pilot_profile?.health_test_photo_base_64?.content} width="100%" style={{ minHeight: '400px' }} />
                                        </Zoom>
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.COPILOT_NAME')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{selectedItemData?.pilot_profile.copilot_name}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.COPILOT_LICENCE_NUMBER')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{selectedItemData?.pilot_profile.copilot_licence_number}</b>
                                </Text>
                            </Grid>
                        </Grid>
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="md"
                open={aircraftInfoOpen}
                onClose={modalAircraftInfoClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    style: {
                        transformOrigin: clickedExtraActionButton.current?.clientX + 'px ' + clickedExtraActionButton.current?.clientY + 'px 0'
                    },
                    timeout: 250
                }}
            >
                <DialogTitle
                    sx={{
                        p: 3,
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t((resource + '.AIRCRAFT_INFO').toUpperCase())}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t((resource + '.AIRCRAFT_INFO_DESC').toUpperCase())}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {!selectedItemData?.aircraftInfo ?
                        <Typography>No se encontró información de la aeronave</Typography> :
                        <Typography variant="subtitle2">
                            <Grid container spacing={0}>
                                {(() => {
                                    let fieldList = [];
                                    for (const property in selectedItemData?.aircraftInfo) {
                                        if (
                                            property != 'extra' &&
                                            property != 'pilot_profile' &&
                                            property != 'airworthiness_certificate_photo_url' &&
                                            property != 'insurance_photo_url' &&
                                            property != 'airworthiness_certificate_photo_base_64' &&
                                            property != 'insurance_photo_base_64'
                                        ) {
                                            switch (property) {
                                                case 'emergency_radio':
                                                    fieldList.push(
                                                        [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                            <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                                {t(('FIELDS.' + property).toUpperCase())}:
                                                            </Box>
                                                        </Grid>,
                                                        <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                            <Text key={property + '-tag-text'} color="black">
                                                                <b>{['U', 'V', 'E'].filter(v => !selectedItemData?.aircraftInfo[property].includes(v))}</b>
                                                            </Text>
                                                        </Grid>]
                                                    );
                                                    break;
                                                case 'surveliance_equipment':
                                                    fieldList.push(
                                                        [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                            <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                                {t(('FIELDS.' + property).toUpperCase())}:
                                                            </Box>
                                                        </Grid>,
                                                        <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                            <Text key={property + '-tag-text'} color="black">
                                                                <b>{['S', 'P', 'D', 'M', 'J'].filter(v => !selectedItemData?.aircraftInfo[property].includes(v))}</b>
                                                            </Text>
                                                        </Grid>]
                                                    );
                                                    break;
                                                case 'jackets':
                                                    fieldList.push(
                                                        [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                            <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                                {t(('FIELDS.' + property).toUpperCase())}:
                                                            </Box>
                                                        </Grid>,
                                                        <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                            <Text key={property + '-tag-text'} color="black">
                                                                <b>{['J', 'L', 'F', 'U', 'V'].filter(v => !selectedItemData?.aircraftInfo[property].includes(v))}</b>
                                                            </Text>
                                                        </Grid>]
                                                    );
                                                    break;
                                                case 'dinghles':
                                                    fieldList.push(
                                                        [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                            <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                                {t(('FIELDS.' + property).toUpperCase())}:
                                                            </Box>
                                                        </Grid>,
                                                        <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                            <Text key={property + '-tag-text'} color="black">
                                                                <b>{selectedItemData?.aircraftInfo[property].length ? '' : 'D'}</b>
                                                            </Text>
                                                        </Grid>]
                                                    );
                                                    break
                                                case 'dinghles_cover':
                                                    fieldList.push(
                                                        [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                            <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                                {t(('FIELDS.' + property).toUpperCase())}:
                                                            </Box>
                                                        </Grid>,
                                                        <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                            <Text key={property + '-tag-text'} color="black">
                                                                <b>{selectedItemData?.aircraftInfo[property].length ? '' : 'C'}</b>
                                                            </Text>
                                                        </Grid>]
                                                    );
                                                    break

                                                default:
                                                    fieldList.push(
                                                        [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                            <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                                {t(('FIELDS.' + property).toUpperCase())}:
                                                            </Box>
                                                        </Grid>,
                                                        <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                            <Text key={property + '-tag-text'} color="black">
                                                                <b>{selectedItemData?.aircraftInfo[property]}</b>
                                                            </Text>
                                                        </Grid>]
                                                    );
                                                    break;
                                            }
                                        }
                                    }
                                    return fieldList;
                                })()}
                            </Grid>
                            {
                                (selectedItemData.aircraftInfo.created_by == user.id || (user.role_id == 1 || user.role_id == 2 || user.role_id == 5)) &&
                                <Grid container display={'flex'} justifyContent={'space-evenly'}>
                                    <Grid item xs={12} md={6} padding={1}>
                                        <Text color="black">
                                            {t('FIELDS.AIRWORTHINESS_CERTIFICATE_PHOTO_URL')}
                                            {
                                                selectedItemData?.aircraftInfo['airworthiness_certificate_photo_base_64'] &&
                                                selectedItemData?.aircraftInfo['airworthiness_certificate_photo_base_64']?.mime != 'application/pdf' &&
                                                <a href={selectedItemData?.aircraftInfo['airworthiness_certificate_photo_base_64']?.content}>{t('GENERAL.DOWNLOAD')}</a>
                                            }
                                        </Text>
                                        {
                                            selectedItemData?.aircraftInfo['airworthiness_certificate_photo_base_64'] &&
                                                selectedItemData?.aircraftInfo['airworthiness_certificate_photo_base_64']?.mime != 'application/pdf' ?
                                                < Zoom >
                                                    <img style={{ width: '90%' }} src={selectedItemData?.aircraftInfo['airworthiness_certificate_photo_base_64']?.content}></img>
                                                </Zoom>
                                                :
                                                <embed src={selectedItemData?.aircraftInfo['airworthiness_certificate_photo_base_64']?.content} width="100%" style={{ minHeight: '400px' }} />
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6} padding={1}>
                                        <Text color="black">
                                            {t('FIELDS.INSURANCE_PHOTO_URL')}
                                            &nbsp;
                                            {
                                                selectedItemData?.aircraftInfo['insurance_photo_base_64'] &&
                                                selectedItemData?.aircraftInfo['insurance_photo_base_64']?.mime != 'application/pdf' &&
                                                <a href={selectedItemData?.aircraftInfo['insurance_photo_base_64']?.content} download>{t('GENERAL.DOWNLOAD')}</a>
                                            }
                                        </Text>
                                        {
                                            selectedItemData?.aircraftInfo['insurance_photo_base_64'] &&
                                                selectedItemData?.aircraftInfo['insurance_photo_base_64']?.mime != 'application/pdf' ?
                                                < Zoom >
                                                    <img style={{ width: '90%' }} src={selectedItemData?.aircraftInfo['insurance_photo_base_64']?.content}></img>
                                                </Zoom>
                                                :
                                                <embed src={selectedItemData?.aircraftInfo['insurance_photo_base_64']?.content} width="100%" style={{ minHeight: '400px' }} />
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Typography>
                    }
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={authorizeRejectModal}
                onClose={authorizeRejectModalClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    style: {
                        transformOrigin: clickedExtraActionButton.current?.clientX + 'px ' + clickedExtraActionButton.current?.clientY + 'px 0'
                    },
                    timeout: 250
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    {authorizeRejectModalMode == 'authorize' ?
                        <CheckCircleIcon fontSize={"large"} sx={{ zoom: 2 }} color='success' /> :
                        <CancelIcon fontSize={"large"} sx={{ zoom: 2 }} color='error' />
                    }

                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 6,
                        }}
                        variant="h3"
                    >
                        {authorizeRejectModalMode == 'authorize' ?
                            t("FLIGHT-PLANS.AUTHORIZE_CONFIRMATION") :
                            t("FLIGHT-PLANS.REJECT_CONFIRMATION")
                        }
                    </Typography>

                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            helperText={t('FLIGHT-PLANS.AUTH_COMMENT')}
                            label={t("FIELDS.COMMENT")}
                            value={authComment}
                            onChange={(event) => setAuthComment(event.target.value)}
                            variant="outlined"
                            sx={{ marginBottom: 3 }}
                        />
                    </Grid>

                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1,
                            }}
                            onClick={authorizeRejectModalClose}
                        >
                            {t("GENERAL.CANCEL")}
                        </Button>
                        <Button
                            onClick={() => {
                                authorizeRejectModalMode == 'authorize' ?
                                    authorizeFlightPlan() :
                                    rejectFlightPlan()
                            }}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3,
                            }}
                            variant="contained"
                        >
                            {t("GENERAL.CONTINUE")}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
}