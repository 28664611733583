const enJSON = {
    MY_ACCOUNT: {
        HEADING: "My account",
        SUBHEADING: "Account configuration",
        USER_DETAILS: "User details",
        USER_DETAILS_DESCRIPTION: "Manage user information",
        BTN_CHANGE_PASSWORD: "Change password",
        BTN_EDIT: "Edit"
    },
};

export default enJSON;
