import { FC } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import anyToString from "src/utils/anyToString";

interface AirportFormProps {
    modalCloseCallback,
    onSuccessCallback
    data?: any,
    extra?: any
}

const AirportForm: FC<AirportFormProps> = ({ modalCloseCallback, onSuccessCallback, data, extra }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Formik
                initialValues={{
                    name: data ? data.name : '',
                    submit: null
                }}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().required(t('VALIDATION.REQUIRED')).min(4, 'Mínimo 4 caracteres').max(4, 'Máximo 4 caracteres')
                    })
                }
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        http.put('api/airports/' + data.id, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.data.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        http.post('api/airports', _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback()
                                enqueueSnackbar(response.data.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name && errors.name.toString()}
                                        label={t("FIELDS.NAME")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                            }}
                        >
                            <Button color="secondary" onClick={modalCloseCallback}>
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={Boolean(errors.submit) || isSubmitting}
                                variant="contained"
                            >
                                {t(!data ? "AIRPORTS.CREATE" : 'AIRPORTS.EDIT')}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default AirportForm;