import Authenticated from "src/components/Authenticated";
import BaseLayout from "src/layouts/BaseLayout";

//Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";

//Routes and Elements
import baseRoutes from "./base";
import { Users } from "src/pages/Users";
import AccentHeaderLayout from "src/layouts/AccentHeaderLayout";
import BottomNavigationLayout from "src/layouts/BottomNavigationLayout";
import AccentSidebarLayout from "src/layouts/AccentSidebarLayout";
import BoxedSidebarLayout from "src/layouts/BoxedSidebarLayout";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";
import ExtendedSidebarLayout from "src/layouts/ExtendedSidebarLayout";
import TopNavigationLayout from "src/layouts/TopNavigationLayout";
import Dashboard from "src/pages/Dashboard";
import MyAccount from "src/pages/MyAccount";
import { Roles } from "src/pages/Roles";
import { Navigate } from "react-router-dom";
import { PilotAircrafts } from "src/pages/PilotAircrafts";
import { GasolineInventoryMovements } from "src/pages/GasolineInventoryMovements";
import { GasolineLoads } from "src/pages/GasolineLoads";
import { FlightPlans } from "src/pages/FlightPlans";
import Reports from "src/pages/Reports";
import { Airports } from "src/pages/Airports";
import { OperationBases } from "src/pages/OperationBases";
import { AircraftTypes } from "src/pages/AircraftTypes";
import { PilotProfiles } from "src/pages/PilotProfiles";
import { Notams } from "src/pages/Notams";
import GeneralFlightPlan from "src/pages/GeneralFlightPlan";

let layout = <BoxedSidebarLayout />;
switch (localStorage.getItem("layout")) {
  case "EXTENDED_SIDEBAR":
    layout = <ExtendedSidebarLayout />;
    break;
  case "ACCENT_HEADER":
    layout = <AccentHeaderLayout />;
    break;
  case "ACCENT_SIDEBAR":
    layout = <AccentSidebarLayout />;
    break;
  case "BOXED_SIDEBAR":
    layout = <BoxedSidebarLayout />;
    break;
  case "COLLAPSED_SIDEBAR":
    layout = <CollapsedSidebarLayout />;
    break;
  case "BOTTOM_NAVIGATION":
    layout = <BottomNavigationLayout />;
    break;
  case "TOP_NAVIGATION":
    layout = <TopNavigationLayout />;
    break;
  default:
    break;
}

const router = [
  {
    path: "/",
    element: <BaseLayout />,
    children: baseRoutes
  },

  {
    path: "admin",
    element: <Authenticated>{layout}</Authenticated>,
    children: [
      {
        path: "",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <Dashboard />
      },
      {
        path: 'roles',
        element: <Roles />
      },
      {
        path: "users",
        element: <Users />
      },
      {
        path: 'my-account',
        element: <MyAccount />
      },
      {
        path: 'pilot-aircrafts',
        element: <PilotAircrafts />
      },
      {
        path: 'gasoline-inventory-movements',
        element: <GasolineInventoryMovements />
      },
      {
        path: 'gasoline-loads',
        element: <GasolineLoads />
      },
      {
        path: 'flight-plans',
        element: <FlightPlans />
      },
      {
        path: 'reports',
        element: <Reports />
      },
      {
        path: 'airports',
        element: <Airports />
      },
      {
        path: 'operation-bases',
        element: <OperationBases />
      },
      {
        path: 'aircraft-types',
        element: <AircraftTypes />
      },
      {
        path: 'pilot-profiles',
        element: <PilotProfiles />
      },
      {
        path: 'notams',
        element: <Notams />
      },
      {
        path: 'general-flight-plan',
        element: <GeneralFlightPlan />
      }
    ],
  },
];

export default router;
