import { FC, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import wait from "src/utils/wait";
import {
    Grid,
    DialogActions,
    DialogContent,
    Zoom,
    TextField,
    CircularProgress,
    Button,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import { useSnackbar } from "notistack";
import Label from "src/components/Label";
import Checkbox from '@mui/material/Checkbox';
import http from "src/utils/httpHelper";
import Text from "src/components/Text";
import anyToString from "src/utils/anyToString";

interface GasolineInventoryMovementsFormProps {
    modalCloseCallback,
    onSuccessCallback,
    data?: any,
    extra?: any
}

const GasolineInventoryMovementsForm: FC<GasolineInventoryMovementsFormProps> = ({ modalCloseCallback, onSuccessCallback, data, extra }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Formik
                initialValues={{
                    movement_type: 0,
                    movement_quantity: data ? data.movement_quantity : "",
                    movement_price: data ? data.movement_price : "",
                    comment: data ? data.comment : "",
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    movement_type: Yup.number()
                        .required(t("VALIDATION.REQUIRED")),
                    movement_quantity: Yup.number()
                        .required(t("VALIDATION.REQUIRED")).min(1, 'Mínimo 1'),
                    movement_price: Yup.number()
                        .required(t("VALIDATION.REQUIRED")).min(1, 'Mínimo 1'),
                    comment: Yup.string()
                        .max(191),
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        http.put('api/gasoline-inventory-movements/' + data.id, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(t('GASOLINE-INVENTORY-MOVEMENTS.EDITED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        http.post('api/gasoline-inventory-movements', _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback()
                                enqueueSnackbar(t('GASOLINE-INVENTORY-MOVEMENTS.SAVED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="movement-type-label">{t('FIELDS.MOVEMENT_TYPE')}</InputLabel>
                                        <Select
                                            labelId="movement-type-label"
                                            id="movement-type-select"
                                            value={values.movement_type}
                                            label={t('FIELDS.MOVEMENT_TYPE')}
                                            onChange={handleChange}
                                            name="movement_type"
                                        >
                                            <MenuItem value={0}>{t('GASOLINE-INVENTORY-MOVEMENTS.IN')}</MenuItem>
                                            <MenuItem value={1}>{t('GASOLINE-INVENTORY-MOVEMENTS.OUT')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.movement_quantity && errors.movement_quantity
                                        )}
                                        fullWidth
                                        helperText={touched.movement_quantity && errors.movement_quantity && errors.movement_quantity.toString()}
                                        label={t("FIELDS.MOVEMENT_QUANTITY")}
                                        name="movement_quantity"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.movement_quantity}
                                        variant="outlined"
                                        type={'number'}
                                        InputProps={{ inputProps: { min: 0, step: '.0001' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.movement_price && errors.movement_price)}
                                        fullWidth
                                        helperText={touched.movement_price && errors.movement_price && errors.movement_price.toString()}
                                        label={t("FIELDS.MOVEMENT_PRICE")}
                                        name="movement_price"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.movement_price}
                                        variant="outlined"
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.comment && errors.comment)}
                                        fullWidth
                                        helperText={touched.comment && errors.comment && errors.comment.toString()}
                                        label={t("FIELDS.COMMENT")}
                                        name="comment"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.comment}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                            }}
                        >
                            <Button color="secondary" onClick={modalCloseCallback}>
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={Boolean(errors.submit) || isSubmitting}
                                variant="contained"
                            >
                                {t(!data ? "GASOLINE-INVENTORY-MOVEMENTS.CREATE" : 'GASOLINE-INVENTORY-MOVEMENTS.EDIT')}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default GasolineInventoryMovementsForm;