import { Box, Button, Card, Grid, Link, MenuItem, Tab, Tabs, TextField, useTheme } from "@mui/material";
import PageHeader from "src/components/PageHeader";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";
import SummarizeIcon from '@mui/icons-material/Summarize';
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from "@mui/lab/TabList";
import moment from "moment";
import { downloadFile } from "src/utils/httpHelper";

function Reports() {
    const { t } = useTranslation();
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = useState('pilots');
    const [gasolineLoadsFilters, setGasolineLoadsFilters] = useState({
        url: 'https://api.sch.sokodev.com/api/reports/gasoline-loads',
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
        type: 'all',
        get urlWithFilters() { return this.url + '?from=' + this.from + '&to=' + this.to + '&type=' + this.type }
    });
    const [flightPlansFilters, setFlightPlansFilters] = useState({
        url: 'https://api.sch.sokodev.com/api/reports/flight-plans',
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
        get urlWithFilters() { return this.url + '?from=' + this.from + '&to=' + this.to }
    });
    const [commandFilters, setCommandFilters] = useState({
        url: 'https://api.sch.sokodev.com/api/reports/command',
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
        get urlWithFilters() { return this.url + '?from=' + this.from + '&to=' + this.to }
    });

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <PageHeader heading={t('REPORTS.HEADING')} subheading={t('REPORTS.SUBHEADING')} icon={<SummarizeIcon />} />
            <Grid
                sx={{
                    px: 4,
                }}
                container
                spacing={4}
            >
                <Grid item
                    xs={12}
                    md={12}>
                    <Card>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={selectedTab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange}
                                        TabIndicatorProps={{ sx: { display: 'none' } }}
                                        sx={{
                                            height: 'auto',
                                            '& .MuiTabs-flexContainer': {
                                                flexWrap: 'wrap',
                                                justifyContent: 'center',
                                            },
                                            '& .Mui-selected': {
                                                color: 'rgb(254, 0, 2) !important'
                                            }
                                        }}>
                                        <Tab sx={{ fontSize: '1.2em' }} label={t('REPORTS.PILOTS')} value="pilots" />
                                        <Tab sx={{ fontSize: '1.2em' }} label={t('REPORTS.AIRCRAFTS')} value="aircrafts" />
                                        <Tab sx={{ fontSize: '1.2em' }} label={t('REPORTS.FLIGHT_PLANS')} value="flight_plans" />
                                        <Tab sx={{ fontSize: '1.2em' }} label={t('REPORTS.GASOLINE_LOADS')} value="gasoline_loads" />
                                        <Tab sx={{ fontSize: '1.2em' }} label={t('REPORTS.COMMAND')} value="command" />
                                    </TabList>
                                </Box>
                                <TabPanel value="pilots">
                                    <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                onClick={() => { downloadFile('https://api.sch.sokodev.com/api/reports/pilot-profiles') }}
                                                color="info"
                                                fullWidth
                                                size="large"
                                                variant="contained">
                                                {t('REPORTS.GET_REPORT')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="aircrafts">
                                    <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                onClick={() => { downloadFile('https://api.sch.sokodev.com/api/reports/pilot-aircrafts') }}
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                variant="contained">
                                                {t('REPORTS.GET_REPORT')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="flight_plans">
                                    <Grid container spacing={3} display="flex" justifyContent={'center'}>
                                        <Grid item xs={12} md={6} >
                                            <TextField
                                                type={"date"}
                                                fullWidth
                                                label={t("FIELDS.FROM")}
                                                variant="outlined"
                                                onChange={(event) => {
                                                    let temp = Object.create(flightPlansFilters);
                                                    temp.from = event.target.value;
                                                    setFlightPlansFilters(temp);
                                                }}
                                                value={flightPlansFilters.from}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} >
                                            <TextField
                                                type={"date"}
                                                fullWidth
                                                label={t("FIELDS.TO")}
                                                variant="outlined"
                                                onChange={(event) => {
                                                    let temp = Object.create(flightPlansFilters);
                                                    temp.to = event.target.value;
                                                    setFlightPlansFilters(temp);
                                                }}
                                                value={flightPlansFilters.to}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button onClick={() => { downloadFile(flightPlansFilters.urlWithFilters) }} color="secondary" fullWidth size="large" variant="contained">{t('REPORTS.GET_REPORT')}</Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="gasoline_loads">
                                    <Grid container spacing={3} display="flex" justifyContent={'center'}>
                                        <Grid item xs={12} md={4} >
                                            <TextField
                                                type={"date"}
                                                fullWidth
                                                label={t("FIELDS.FROM")}
                                                variant="outlined"
                                                onChange={(event) => {
                                                    let temp = Object.create(gasolineLoadsFilters);
                                                    temp.from = event.target.value;
                                                    setGasolineLoadsFilters(temp);
                                                }}
                                                value={gasolineLoadsFilters.from}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <TextField
                                                type={"date"}
                                                fullWidth
                                                label={t("FIELDS.TO")}
                                                variant="outlined"
                                                onChange={(event) => {
                                                    let temp = Object.create(gasolineLoadsFilters);
                                                    temp.to = event.target.value;
                                                    setGasolineLoadsFilters(temp);
                                                }}
                                                value={gasolineLoadsFilters.to}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label={t('FIELDS.REGISTERED_OR_FOREIGNERS')}
                                                variant="outlined"
                                                select
                                                fullWidth
                                                onChange={(event) => {
                                                    let temp = Object.create(gasolineLoadsFilters);
                                                    temp.type = event.target.value;
                                                    setGasolineLoadsFilters(temp);
                                                }}
                                                value={gasolineLoadsFilters.type}
                                            >
                                                <MenuItem value={'all'}>Todos</MenuItem>
                                                <MenuItem value={'registered'}>Registrados</MenuItem>
                                                <MenuItem value={'foreigners'}>Foráneos</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button onClick={() => { downloadFile(gasolineLoadsFilters.urlWithFilters) }} color="warning" fullWidth size="large" variant="contained">{t('REPORTS.GET_REPORT')}</Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="command">
                                    <Grid container spacing={3} display="flex" justifyContent={'center'}>
                                        <Grid item xs={12} md={6} >
                                            <TextField
                                                type={"date"}
                                                fullWidth
                                                label={t("FIELDS.FROM")}
                                                variant="outlined"
                                                onChange={(event) => {
                                                    let temp = Object.create(commandFilters);
                                                    temp.from = event.target.value;
                                                    setCommandFilters(temp);
                                                }}
                                                value={commandFilters.from}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} >
                                            <TextField
                                                type={"date"}
                                                fullWidth
                                                label={t("FIELDS.TO")}
                                                variant="outlined"
                                                onChange={(event) => {
                                                    let temp = Object.create(commandFilters);
                                                    temp.to = event.target.value;
                                                    setCommandFilters(temp);
                                                }}
                                                value={commandFilters.to}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button onClick={() => { downloadFile(commandFilters.urlWithFilters) }} color="success" fullWidth size="large" variant="contained">{t('REPORTS.GET_REPORT')}</Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Card>
                </Grid >
            </Grid >
        </>
    );
}

export default Reports;
