const esJSON = {
  LOGO: {
    VERSION: '',
    VERSION_DESC: '',
    SYSTEM_NAME: ''
  },
  DASHBOARD: {
    HEADING: 'Principal',
    SUBHEADING: 'Muestra indicadores de información y configuraciones del sistema',
    GRAPHIC_TITLE: 'Movimientos del inventario de gasolina (últimos 30 días)'
  },
  MY_ACCOUNT: {
    HEADING: "Mi cuenta",
    SUBHEADING: "Información general y configuración de la cuenta",
    USER_DETAILS: "Detalles del usuario",
    USER_DETAILS_DESCRIPTION: "Administra la información de usuario",
    BTN_CHANGE_PASSWORD: "Cambiar contraseña",
    BTN_EDIT: "Editar",
    ACCOUNT_EDIT: "Editar perfil",
    ACCOUNT_EDIT_DESCRIPTION: "Formulario de edición del perfil",
    PROFILE_INFO_UPDATED: "Se ha actualizado la información del perfil",
    PROFILE_INFO_UPDATED_WITH_ERROR: "Se ha actualizado la información del perfil, pero, ocurrió un error al mostrar los datos actualizados.",
    CHANGE_PASSWORD_HEADING: "Cambiar contraseña",
    CHANGE_PASSWORD_SUBHEADING: "Formulario para cambiar la contraseña",
  },
  LOGIN: {
    HEADING: "Iniciar sesión",
    SUBHEADING: "Rellena los campos a continuación para iniciar sesión",
    REGISTER: "Registrarse",
    DONT_YOU_HAVE_AN_ACCOUNT: "¿No tienes una cuenta?",
    LOST_PASSWORD: "¿Olvidaste tu contraseña?"
  },
  REGISTER: {
    HEADING: "Registrar cuenta",
    SUBHEADING: "Completa los campos a continuación para crear una cuenta nueva.",
    BACK_TO: "Regresar a",
    USER_REGISTERED: "Se ha registrado el usuario"
  },
  LANGUAGE_SWITCHER: {
    HEADING: "Lenguaje",
    SUBHEADING: "Cambia el lenguaje"
  },
  RECOVER_PASSWORD: {
    HEADING: "Recuperar contraseña",
    SUBHEADING: "Introduce tu correo electrónico para reiniciar tu contraseña",
    BACK_TO_LOGIN: "Quieres volver a iniciar sesión?",
    INSTRUCTIONS: "Las instrucciones para recuperar tu contraseña se han enviado a tu correo electrónico.",
    CHECK_MAIL: "Revisa tu correo electrónico para recuperar tu contraseña",
    TO_LOGIN: "Ir a iniciar sesión"
  },
  ROLES: {
    HEADING: "Roles",
    SUBHEADING: "Administración de los roles y permisos",
    CREATE: "Crear rol",
    CREATE_DESC: "Completa el formulario para crear un rol",
    EDIT: "Editar rol",
    EDIT_DESC: "Completa el formulario para editar el rol",
    SHOW: "Detalles del rol",
    SHOW_DESC: "Información completa del rol seleccionado",
    DELETE: "Borrar rol",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el rol seleccionado?",
    DELETED: "Se ha borrado el rol seleccionado",
    SEARCH: "Buscar por nombre o descripción",
    PERMISSIONS: "Permisos",
    SAVED: "Se ha guardado el rol.",
    EDITED: "Se ha editado el rol."
  },
  USERS: {
    HEADING: "Usuarios",
    SUBHEADING: "Administración de los usuarios",
    CREATE: "Crear usuario",
    CREATE_DESC: "Completa el formulario para crear un usuario",
    EDIT: "Editar usuario",
    EDIT_DESC: "Completa el formulario para editar el usuario",
    SHOW: "Detalles del usuario",
    SHOW_DESC: "Información completa del usuario seleccionado",
    DELETE: "Borrar usuario",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el usuario seleccionado?",
    DELETED: "Se ha borrado el usuario seleccionado",
    SEARCH: "Buscar por nombre de usuario o correo electrónico",
    ACTIVE: {
      0: 'Desctivado',
      1: 'Activado'
    }
  },
  RESET_PASSWORD: {
    HEADING: "Reiniciar contraseña",
    SUBHEADING: "Llena los campos para reiniciar tu contraseña",
    INSTRUCTIONS: "Ahora puedes iniciar sesión con tu nueva contraseña",
    SUCCESS: "Se ha reiniciado la contraseña",
    TO_LOGIN: "Ir a iniciar sesión"
  },
  EMAIL_VERIFICATION: {
    HEADING: "Verificación de correo",
    SUBHEADING: "Es necesario verificar tu correo electrónico para el uso del sistema. \n Al momento de registrarte se ha enviado un correo de verificación, en caso de no haberlo recibido puedes solicitar otro pulsando el siguiente botón.",
    BUTTON: "Solicitar un correo de verificación",
    SUCCESS: "Se ha enviado el correo de verificación a la dirección registrada"
  },
  VERIFY_EMAIL: {
    HEADING: "Verificación de correo",
    SUBHEADING: "Es necesario que el correo electrónico sea verificado para hacer uso del sistema",
    VERIFYING: "Verificando correo electrónico",
    VERIFIED: "Se ha verificado el correo electrónico con éxito, serás redirigido al inicio",
    VERIFIED_ERROR: "Hubo un error verificando tu correó electrónico, puedes solicitar un nuevo correó de verificacón en el siguiente botón",
    VERIFY: "Verificar correo electrónico",
    BUTTON: "Ir a solicitar un correo de verificación"

  },
  PILOT_PROFILE: {
    HEADING: 'Perfil del piloto',
    SUBHEADING: 'Información general del piloto',
    SAVED: 'Se ha guardado el perfil del piloto',
    UPDATED: 'Se ha actualizado el perfil del piloto.',
    SAVE: 'Guardar'
  },
  'GASOLINE-PRICES': {
    HEADING: 'Configuración de precio de gasolina',
    HISTORY_HEADING: 'Historial de precios de gasolina',
    HISTORY_SUBHEADING: 'Historial de precios de la gasolina en los últimos 30 días',
    SHOW_HISTORY: 'Mostrar el historial de precios',
    SUBMIT_PRICE: 'Guardar precio',
    SAVED: "Se ha guardado el precio de la gasolina"
  },
  'GASOLINE-LOADS': {
    HEADING: "Cargas de gasolina",
    SUBHEADING: "Administración de las cargas de gasolina",
    CREATE: "Crear carga de gasolina",
    CREATE_DESC: "Completa el formulario para crear una carga de gasolina",
    SHOW: "Detalles de la carga de gasolina",
    SHOW_DESC: "Información completa de la carga de gasolina seleccionada",
    SEARCH: "Buscar por matricula o nombre de piloto",
    SAVED: "Se ha guardado la carga de gasolina",
    COMMENT: "Matricula y/o nombre del piloto en caso de ser foráneo"
  },
  'GASOLINE-INVENTORY-MOVEMENTS': {
    HEADING: "Movimientos de inventario",
    SUBHEADING: "Administración de los movimientos de inventario",
    CREATE: "Crear movimiento de inventario",
    CREATE_DESC: "Completa el formulario para crear un movimiento de inventario",
    SHOW: "Detalles del movimiento de inventario",
    SHOW_DESC: "Información completa del movimiento de inventario",
    SEARCH: "Buscar en los comentarios",
    SAVED: "Se ha guardado el movimiento de inventario",
    IN: 'Entrada',
    OUT: 'Salida'
  },
  'PILOT-AIRCRAFTS': {
    HEADING: "Aeronaves de piloto",
    SUBHEADING: "Administración de las aeronaves de los pilotos",
    CREATE: "Crear aeronave",
    CREATE_DESC: "Completa el formulario para crear una aeronave",
    EDIT: "Editar aeronave",
    EDIT_DESC: "Completa el formulario para editar la aeronave",
    SHOW: "Detalles de la aeronave",
    SHOW_DESC: "Información completa de la aeronave",
    SEARCH: "Buscar con la matrícula",
    SAVED: "Se ha guardado la aeronave",
    EDITED: 'Se ha actualizado la aeronave'
  },
  'FLIGHT-PLANS': {
    HEADING: "Planes de vuelo XSCH",
    SUBHEADING: "Administración de los planes de vuelo",
    CREATE: "Crear plan de vuelo",
    CREATE_DESC: "Completa el formulario para crear un plan de vuelo",
    SHOW: "Detalles del plan de vuelo",
    SHOW_DESC: "Información completa del plan de vuelo",
    SEARCH: "Buscar por piloto o licencia",
    SAVED: "Se ha guardado el plan de vuelo",
    EDIT: "Editar plan de vuelo",
    EDIT_DESC: "Competa el formulario para editar el plan de vuelo",
    EDITED: "Se ha actualizado el plan de vuelo",
    PILOT: "Ver información del piloto",
    PDF: "Ver pdf del plan de vuelo",
    AUTHORIZE: "Autorizar plan de vuelo",
    REJECT: "Rechazar plan de vuelo",
    AUTHORIZE_CONFIRMATION: "¿Está seguro que desea autorizar el plan de vuelo?",
    REJECT_CONFIRMATION: "¿Está seguro que desea rechazar el plan de vuelo?",
    PILOT_INFO: "Información del piloto",
    PILOT_INFO_DESC: "Información general del piloto",
    AUTH_COMMENT: "Este comentario será incluido en el correo que se envía al piloto",
    AIRCRAFT_INFO: 'Información de la aeronave',
    AIRCRAFT_INFO_DESC: 'Información general de la aeronave',
    AIRCRAFT: 'Ver información de la aeronave',
    DELETE: 'Borrar plan de vuelo',
    DELETE_CONFIRM_MESSAGE: "¿Está seguro de que desea borrar el plan de vuelo?"
  },
  'AIRPORTS': {
    HEADING: "Aeropuertos",
    SUBHEADING: "Administración de los aeropuertos",
    CREATE: "Crear aeropuerto",
    CREATE_DESC: "Completa el formulario para crear un aeropuerto",
    SHOW: "Detalles del aeropuerto",
    SHOW_DESC: "Información completa del aeropuerto",
    SAVED: "Se ha guardado el aeropuerto",
    EDIT: "Editar aeropuerto",
    EDIT_DESC: "Competa el formulario para editar el aeropuerto",
    EDITED: "Se ha actualizado el aeropuerto",
    DELETE: "Borrar aeropuerto",
    DELETE_CONFIRM_MESSAGE: "¿Está seguro de que desea borrar el aeropuerto?"
  },
  'OPERATION-BASES': {
    HEADING: "Bases de operaciones",
    SUBHEADING: "Administración de las bases de operaciones",
    CREATE: "Crear base de operaciones",
    CREATE_DESC: "Completa el formulario para crear una base de operaciones",
    SHOW: "Detalles de la base de operaciones",
    SHOW_DESC: "Información completa de la base de operaciones",
    SAVED: "Se ha guardado el base de operaciones",
    EDIT: "Editar base de operaciones",
    EDIT_DESC: "Competa el formulario para editar el base de operaciones",
    EDITED: "Se ha actualizado el base de operaciones",
    DELETE: "Borrar base de operaciones",
    DELETE_CONFIRM_MESSAGE: "¿Está seguro de que desea borrar el base de operaciones?"
  },
  'AIRCRAFT-TYPES': {
    HEADING: "Tipos de aeronaves",
    SUBHEADING: "Administración de los tipos de aeronaves",
    CREATE: "Crear tipo de aeronave",
    CREATE_DESC: "Completa el formulario para crear un tipo de aeronave",
    SHOW: "Detalles del tipo de aeronave",
    SHOW_DESC: "Información completa del tipo de aeronave",
    SAVED: "Se ha guardado el tipo de aeronave",
    EDIT: "Editar tipo de aeronave",
    EDIT_DESC: "Competa el formulario para editar el tipo de aeronave",
    EDITED: "Se ha actualizado el tipo de aeronave",
    DELETE: "Borrar tipo de aeronave",
    DELETE_CONFIRM_MESSAGE: "¿Está seguro de que desea borrar el tipo de aeronave?"
  },
  'PILOT-PROFILES': {
    HEADING: "Pilotos",
    SUBHEADING: "Administración de los pilotos",
    CREATE: "Crear piloto",
    CREATE_DESC: "Completa el formulario para crear un piloto",
    SHOW: "Detalles del piloto",
    SHOW_DESC: "Información completa del piloto",
    SAVED: "Se ha guardado el piloto",
    EDIT: "Editar piloto",
    EDIT_DESC: "Competa el formulario para editar el piloto",
    EDITED: "Se ha actualizado el piloto",
    SEARCH: "Buscar por el nombre o número de licencia"
  },
  'REPORTS': {
    HEADING: "Reportes",
    SUBHEADING: "Exportar a excel la información del sistema",
    PILOTS: "Pilotos registrados",
    AIRCRAFTS: "Aeronaves registradas",
    FLIGHT_PLANS: "Planes de vuelo",
    GASOLINE_LOADS: "Cargas de gasolina",
    COMMAND: 'Reporte a comandancia',
    GET_REPORT: 'Descargar reporte'
  },
  THEME_SETTINGS: {
    CHOOSE_LAYOUT: "Selecciona la distribución",
    CHOOSE_THEME: "Selecciona el tema",
    ADMIN_PANEL_LAYOUT: "Distribución del panel de administrador",
    ADMIN_PANEL_LAYOUT_DESCRIPTION: "Cambia el acomodo de la información",
    ADMIN_PANEL_THEME: "Tema del panel de administrador",
    ADMIN_PANEL_THEME_DESCRIPTION: "Cambia los colores",
    THEMES: {
      PURE_LIGHT_THEME: "Pure Light",
      GRAY_GOOSE_THEME: "Gray Goose",
      PURPLE_FLOW_THEME: "Purple Flow"
    },
    LAYOUTS: {
      EXTENDED_SIDEBAR: "Extended Sidebar",
      ACCENT_HEADER: "Accent Header",
      ACCENT_SIDEBAR: "Accent Sidebar",
      BOXED_SIDEBAR: "Boxed Sidebar",
      COLLAPSED_SIDEBAR: "Collapsed Sidebar",
      BOTTOM_NAVIGATION: "Bottom Navigation",
      TOP_NAVIGATION: "Top Navigation"
    },
  },
  'NOTAMS': {
    HEADING: "Notams",
    SUBHEADING: "Administración de los notams",
    CREATE: "Crear notam",
    CREATE_DESC: "Completa el formulario para crear un notam",
    SHOW: "Detalles del notam",
    SHOW_DESC: "Información completa del notam",
    SAVED: "Se ha guardado el notam",
    EDIT: "Editar notam",
    EDIT_DESC: "Competa el formulario para editar el notam",
    EDITED: "Se ha actualizado el notam",
    DELETE: "Borrar notam",
    DELETE_CONFIRM_MESSAGE: "¿Está seguro de que desea borrar el notam?",
    SEVERITY: {
      'info': 'Informativo',
      'success': 'Éxito',
      'warning': 'Advertencia',
      'error': 'Error'
    }
  },
  'GENERAL-FLIGHT-PLAN': {
    HEADING: "Plan de vuelo general",
    SUBHEADING: "Completa el formulario para generar un plan de vuelo. La información no se guarda, solo genera un PDF del plan de vuelo.",
    CREATE: "Generar pdf de plan de vuelo",
    AIRCRAFT_HELPER_TEXT: "Seleccionar si se quiere autollenar los campos con los datos de alguna de las aeronaves registradas."
  },
  FIELDS: {
    ID: 'Id',
    USERNAME: "Usuario",
    EMAIL: "Correo electrónico",
    NAME: "Nombre",
    LASTNAME: "Apellido",
    CURRENT_PASSWORD: "Contrseña actual",
    PASSWORD: "Contraseña",
    PASSWORD_CONFIRMATION: "Confirmación de contraseña",
    REMEMBER: "Recuerdame",
    DESCRIPTION: "Descripción",
    CREATED_AT: "Creado",
    UPDATED_AT: "Actualizado",
    ROLE: "Rol",
    ROLE_ID: "Rol",
    ACTIVE: "Estado",
    EMAIL_VERIFIED_AT: 'Verificado',

    //flight plans
    ARRIVE_OR_DEPART: 'Llegada o salida',
    COMPANY: 'Empresa o propietario',
    OPERATION_TYPE: 'Tipo de operación',
    NATIONAL_OR_INTERNATIONAL: 'Nacional o internacional',
    AIRCRAFT_BRAND: 'Marca de la aeronave',
    AIRCRAFT_MODEL: 'Modelo de la aeronave',
    AIRCRAFT_PLATE: 'Matricula de la aeronave',
    OPERATION_TIME: 'Hora de operación',
    DATE: 'Fecha',
    AIRCRAFT_IDENTIFICATION: 'Identificación de aeronave',
    FLIGHT_RULES: 'Reglas de vuelo',
    TYPE_OF_FLIGHT: 'Tipo de vuelo',
    AIRCRAFT_NUMBER: 'Número',
    TYPE_OF_AIRCRAFT: 'Tipo de aeronave',
    WAKE_TURBULENCE_CAT: 'Cat. de estela turbulenta',
    RADIOCOMUNICATION: 'Radiocomunicaciones de ayudas',
    SURVEILANCE: 'Vigilancia',
    DEPARTURE_AERODROME: 'Aeropuerto de salida',
    DEPARTURE_TIME: 'Hora',
    CRUSISING_SPEED: 'Velocidad de crucero',
    LEVEL: 'Nivel',
    ROUTE: 'Ruta',
    DESTINATION_AERODROME: 'Aeropuerto de destino',
    TOTAL_EET: 'Total EET',
    ALTN_AERODROME: 'Aeropuerto alt.',
    SECOND_ALTN_AERODROME: '2º Aueropuerto alt.',
    OTHER_INFORMATION: 'Otros datos',
    ENDURANCE: 'Autonomía',
    PERSONS_ON_BOARD: 'Personas a bordo',
    EMERGENCY_RADIO: 'Equipo radio de emergencia',
    SURVELIANCE_EQUIPMENT: 'Equipo de supervivencia',
    JACKETS: 'Chalecos',
    DINGHLES: 'Botes neumaticos',
    DINGHLES_NUMBER: 'Número',
    DINGHLES_CAPACITY: 'Capacidad',
    DINGHLES_COVER: 'Cubierta',
    DINGHLES_COLOR: 'Color',
    AIRCRAFT_COLOUR_AND_MARKINGS: 'Color y marcas de la aeronave',
    REMARKS: 'Observaciones',
    PILOT_IN_COMMAND: 'Piloto al mando',
    LICENCE_NUMBER: 'No. Lic',
    ADDRESS: 'Dirección',
    ADITIONAL_SERVICES: 'Servicios adicionales',
    OTHER_SERVICE: 'Otro servicio',
    AUTH_NUMBER: 'Folio de autorización',
    AIRCRAFT_IDENTIFICATION_PLATE: 'Matrícula',
    AIRCRAFT_GENERAL: 'Aeronave registrada',

    //gasoline movements
    MOVEMENT_TYPE: 'Tipo de movimiento',
    MOVEMENT_QUANTITY: 'Litros reales',
    MOVEMENT_PRICE: 'Precio',
    COMMENT: 'Comentario',
    PILOT: 'Piloto',
    MOVEMENT_TOTAL: 'Importe',

    //gasoline loads
    FOLIO: 'Folio',
    PRICE: 'Precio',
    TOTAL: 'Total',
    AIRCRAFT_INFO: 'Información de aeronave',
    LOAD_DATE: 'Fecha de carga',
    INIT_LOAD: 'Tacómetro inicial',
    FINAL_LOAD: 'Tacómetro final',
    QUANTITY: 'Litros reales',
    PAYMENT_METHOD: 'Método de pago',
    AIRCRAFT: 'Aeronave',
    LOADED_BY: "Cargado por",
    AMOUNT: 'Importe',
    INVOICE: 'Factura',
    INIT_LOAD_TIME: 'Hora de inicio de carga',
    FINAL_LOAD_TIME: 'Hora de finalización de carga',
    LITERS: 'Litros',

    //pilot aircrafts
    BRAND: 'Marca',
    MODEL: 'Modelo',
    PLATE: 'Matrícula',
    AIRCRAFT_TYPE: 'Tipo de aeronave',
    COMMUNICATION_EQUIPMENT: 'Equipo de comunicación',
    PILOT_PROFILE_ID: 'Piloto',
    AIRWORTHINESS_CERTIFICATE_PHOTO: 'Foto del certificado de aeronavegabilidad',
    INSURANCE_PHOTO: 'Foto del seguro vigente',
    AIRWORTHINESS_CERTIFICATE_PHOTO_URL: 'Foto del certificado de aeronavegabilidad',
    INSURANCE_PHOTO_URL: 'Foto del seguro vigente',
    PILOT_PROFILES: 'Pilotos asignados',
    CREATED_BY: 'Creado por',

    //pilot profile
    CELLPHONE: 'Teléfono celular',
    OPERATION_BASE: 'Base de operación',
    PILOT_LICENCE_PHOTO: 'Foto de la licencia mexicana',
    INE_PHOTO: 'Foto de la INE',
    HEALTH_TEST_PHOTO: 'Foto del examen médico',
    COPILOT_NAME: 'Nombre del copiloto',
    COPILOT_LICENCE_NUMBER: 'Número de licencia del copiloto',
    STATUS: 'Estado',
    PILOT_LICENCE_REVERSE_PHOTO: 'Foto de la licencia mexicana (Reverso)',
    PILOT_USA_LICENCE_PHOTO: 'Foto de la licencia americana',
    PILOT_USA_LICENCE_REVERSE_PHOTO: 'Foto de la licencia americana (Reverso)',

    //Notams
    MESSAGE: 'Mensaje',
    INIT_DATE: 'Fecha inicial',
    END_DATE: 'Fecha final',
    SEVERITY: 'Gravedad',

    //OTHERS
    FROM: 'Desde',
    TO: 'Hasta',
    REGISTERED_OR_FOREIGNERS: 'Registrados o foráneos'
  },
  VALIDATION: {
    REQUIRED: "El campo es requerido.",
    EMAIL: "El campo debe ser un correo electrónico válido.",
    MIN_ONE: "Es necesario seleccionar al menos 1."
  },
  GENERAL: {
    DOWNLOAD: "Descargar",
    CANCEL: "Cancelar",
    LOGOUT: "Cerrar sesión",
    CLOSE: "Cerrar",
    NO_RESULTS: "No se han econtrado resultados",
    SEARCH: "Buscar",
    ACTIONS: "Acciones",
    GO_TO_HOMEPAGE: "Volver a inicio",
    CONTINUE: "Continuar",
    BULK_ACTIONS: "Acciones masivas",
    BULK_DELETE: "Borrar seleccionados"
  },
  STATUS: {
    404: {
      MESSAGE: "La página que buscas no existe."
    },
    403: {
      MESSAGE: "No tienes los permisos para realizar esta acción."
    }
  }
};

export default esJSON;
