import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Grid,
    Box,
    TextField,
    CircularProgress,
    Avatar,
    IconButton,
    Button,
    styled,
    Dialog,
    DialogTitle,
    Typography,
    Tooltip,
    DialogContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { useTranslation } from 'react-i18next';
import http from 'src/utils/httpHelper';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import Moment from 'react-moment';
import anyToString from 'src/utils/anyToString';

function GasolinePriceForm() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [pricesHistory, setPricesHistory] = useState([]);
    const modalOpen = () => {
        setOpen(true);
    };

    const modalClose = () => {
        setOpen(false);
    };

    const refreshPricesHistory = () => {
        http.get('/api/gasoline-prices')
            .then(response => {
                setPricesHistory(response.data);
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }
    useEffect(() => {
        refreshPricesHistory();
    }, []);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    price: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    price: Yup.number()
                        .max(255)
                        .required(t('VALIDATION.REQUIRED'))
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    http.post('/api/gasoline-prices', _values)
                        .then((response: any) => {
                            setStatus({ success: true });
                            resetForm();
                            enqueueSnackbar(t('GASOLINE-PRICES.SAVED'), { variant: 'success' });
                            refreshPricesHistory();
                        })
                        .catch((error) => {
                            console.error(error);
                            enqueueSnackbar(error.message, { variant: 'error' });
                            setStatus({ success: false });
                            setErrors({ submit: error.message });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h4" gutterBottom>
                                    {t('GASOLINE-PRICES.HEADING')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            error={Boolean(touched.price && errors.price)}
                                            fullWidth
                                            helperText={touched.price && errors.price && errors.price.toString()}
                                            label={t('FIELDS.PRICE')}
                                            name="price"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.price}
                                            variant="outlined"
                                            type='number'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item justifyContent="center" md={12}>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                    fullWidth={true}
                                >
                                    {t('GASOLINE-PRICES.SUBMIT_PRICE')}
                                </Button>
                            </Grid>
                            <Grid item justifyContent="center" md={12}>
                                <Typography onClick={modalOpen} textAlign='center' sx={{ 'textDecoration': 'underline' }}>{t('GASOLINE-PRICES.SHOW_HISTORY')}</Typography>
                                <Dialog
                                    fullWidth
                                    maxWidth="md"
                                    open={open}
                                    onClose={modalClose}
                                >
                                    <DialogTitle
                                        sx={{
                                            p: 3,
                                        }}
                                    >
                                        <Typography variant="h4" gutterBottom>
                                            {t('GASOLINE-PRICES.HISTORY_HEADING')}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {t('GASOLINE-PRICES.HISTORY_SUBHEADING')}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('FIELDS.PRICE')}</TableCell>
                                                    <TableCell>{t('FIELDS.CREATED_AT')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    pricesHistory.map(price => (
                                                        <TableRow key={price.id}>
                                                            <TableCell>{price.price}</TableCell>
                                                            <TableCell><Moment format='DD/MM/YYYY'>{price.created_at}</Moment></TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default GasolinePriceForm;
