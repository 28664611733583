import type { ReactNode } from 'react';
import { MenuItem } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import BusinessIcon from '@mui/icons-material/Business';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  resource?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}


const menuItems: MenuItems[] = [
  {
    heading: 'Menu',
    items: [
      {
        name: 'Principal',
        link: '/admin/dashboard',
        icon: <DashboardIcon />,
        resource: 'dashboard'
      },
      {
        name: 'Roles',
        link: '/admin/roles',
        icon: <VerifiedUserIcon />,
        resource: 'roles'
      },
      {
        name: 'Usuarios',
        link: '/admin/users',
        icon: <PeopleIcon />,
        resource: 'users'
      },
      {
        name: 'Pilotos',
        link: '/admin/pilot-profiles',
        icon: <AccountBoxIcon />,
        resource: 'pilot-profiles'
      },
      {
        name: 'Aeronaves de piloto',
        link: '/admin/pilot-aircrafts',
        icon: <AirplanemodeActiveIcon />,
        resource: 'pilot-aircrafts'
      },
      {
        name: 'Planes de vuelo XSCH',
        link: '/admin/flight-plans',
        icon: <AirplaneTicketIcon />,
        resource: 'flight-plans'
      },
      {
        name: 'Movimientos de inventario de gasolina',
        link: '/admin/gasoline-inventory-movements',
        icon: <InventoryIcon />,
        resource: 'gasoline-inventory-movements'
      },
      {
        name: 'Cargas de gasolina',
        link: '/admin/gasoline-loads',
        icon: <LocalGasStationIcon />,
        resource: 'gasoline-loads'
      },
      {
        name: 'Aeropuertos',
        link: '/admin/airports',
        icon: <FlightLandIcon />,
        resource: 'airports'
      },
      {
        name: 'Bases de operación',
        link: '/admin/operation-bases',
        icon: <BusinessIcon />,
        resource: 'operation-bases'
      },
      {
        name: 'Tipos de aeronaves',
        link: '/admin/aircraft-types',
        icon: <ConnectingAirportsIcon />,
        resource: 'aircraft-types'
      },
      {
        name: 'Reportes',
        link: '/admin/reports',
        icon: <SummarizeIcon />,
        resource: 'reports'
      },
      {
        name: 'Notams',
        link: '/admin/notams',
        icon: <StickyNote2Icon />,
        resource: 'notams'
      },
      {
        name: 'Plan de vuelo general',
        link: '/admin/general-flight-plan',
        icon: <DocumentScannerIcon />,
        resource: 'notams'
      }
    ]
  }
];

export default menuItems;
