import { DialogContent, Grid, Typography } from "@mui/material";
import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Box } from "@mui/system";
import Text from "src/components/Text";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export function PilotProfiles() {
    const { t } = useTranslation();

    const CustomShow = (props) => {
        return (
            <>
                <DialogContent>
                    <Typography variant="subtitle2">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.NAME')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{props.item.name}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.CELLPHONE')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{props.item.cellphone}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.LICENCE_NUMBER')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{props.item.licence_number}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.OPERATION_BASE')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{props.item.operation_base}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.ADDRESS')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{props.item.address}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_LICENCE_PHOTO')}
                                </Text>
                                {
                                    props.item['pilot_licence_photo_base_64'] &&
                                    (props.item['pilot_licence_photo_base_64']['mime'] != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={props.item['pilot_licence_photo_base_64']['content']}></img>
                                        </Zoom>
                                        :
                                        <embed src={props.item['pilot_licence_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }} />)
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_LICENCE_REVERSE_PHOTO')}
                                </Text>
                                {
                                    props.item['pilot_licence_reverse_photo_base_64'] &&
                                    (props.item['pilot_licence_reverse_photo_base_64']['mime'] != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={props.item['pilot_licence_reverse_photo_base_64']['content']}></img>
                                        </Zoom>
                                        :
                                        <embed src={props.item['pilot_licence_reverse_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }} />)
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_USA_LICENCE_PHOTO')}
                                </Text>
                                {
                                    props.item['pilot_usa_licence_photo_base_64'] &&
                                    (props.item['pilot_usa_licence_photo_base_64']['mime'] != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={props.item['pilot_usa_licence_photo_base_64']['content']}></img>
                                        </Zoom>
                                        :
                                        <embed src={props.item['pilot_usa_licence_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }} />)
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Text color="black">
                                    {t('FIELDS.PILOT_USA_LICENCE_REVERSE_PHOTO')}
                                </Text>
                                {
                                    props.item['pilot_usa_licence_reverse_photo_base_64'] &&
                                    (props.item['pilot_usa_licence_reverse_photo_base_64']['mime'] != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={props.item['pilot_usa_licence_reverse_photo_base_64']['content']}></img>
                                        </Zoom>
                                        :
                                        <embed src={props.item['pilot_usa_licence_reverse_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }} />)
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Text color="black">
                                    {t('FIELDS.INE_PHOTO')}
                                </Text>
                                {
                                    props.item['ine_photo_base_64'] &&
                                    (props.item['ine_photo_base_64']['mime'] != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={props.item['ine_photo_base_64']['content']}></img>
                                        </Zoom>
                                        :
                                        <embed src={props.item['ine_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }} />)
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Text color="black">
                                    {t('FIELDS.HEALTH_TEST_PHOTO')}
                                </Text>
                                {
                                    props.item['health_test_photo_base_64'] &&
                                    (props.item['health_test_photo_base_64']['mime'] != 'application/pdf' ?
                                        < Zoom >
                                            <img style={{ width: '90%' }} src={props.item['health_test_photo_base_64']['content']}></img>
                                        </Zoom>
                                        :
                                        <embed src={props.item['health_test_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }} />)
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.COPILOT_NAME')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{props.item.copilot_name}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                <Box pr={3} pb={2}>
                                    {t('FIELDS.COPILOT_LICENCE_NUMBER')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Text color="black">
                                    <b>{props.item.copilot_licence_number}</b>
                                </Text>
                            </Grid>
                        </Grid>
                    </Typography>
                </DialogContent>
            </>
        )
    }
    return (
        <CrudBase
            resource="pilot-profiles"
            indexColumns={['name', 'licence_number']}
            searchText={true}
            showButton={true}
            editButton={false}
            CustomShow={CustomShow}
            icon={<AccountBoxIcon fontSize="large" />}
            createButton={false} />
    );
}
