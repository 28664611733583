import { Box, DialogContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CrudBase from "src/components/CrudBase";
import PilotAircraftsForm from "./PilotAircraftsForm";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import Text from "src/components/Text";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export function PilotAircrafts() {
    const CustomShow = (props) => {
        const { t } = useTranslation();
        return (
            <>
                <DialogContent>
                    <Grid container spacing={0}>
                        {(() => {
                            let fieldList = [];
                            for (const property in props.item) {
                                if (
                                    property != 'extra' &&
                                    property != 'pilot_profile' &&
                                    property != 'airworthiness_certificate_photo_url' &&
                                    property != 'insurance_photo_url' &&
                                    property != 'airworthiness_certificate_photo_base_64' &&
                                    property != 'insurance_photo_base_64'
                                ) {
                                    switch (property) {
                                        case 'emergency_radio':
                                            fieldList.push(
                                                [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                    <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                        {t(('FIELDS.' + property).toUpperCase())}:
                                                    </Box>
                                                </Grid>,
                                                <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                    <Text key={property + '-tag-text'} color="black">
                                                        <b>{['U', 'V', 'E'].filter(v => !props.item[property].includes(v))}</b>
                                                    </Text>
                                                </Grid>]
                                            );
                                            break;
                                        case 'surveliance_equipment':
                                            fieldList.push(
                                                [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                    <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                        {t(('FIELDS.' + property).toUpperCase())}:
                                                    </Box>
                                                </Grid>,
                                                <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                    <Text key={property + '-tag-text'} color="black">
                                                        <b>{['S', 'P', 'D', 'M', 'J'].filter(v => !props.item[property].includes(v))}</b>
                                                    </Text>
                                                </Grid>]
                                            );
                                            break;
                                        case 'jackets':
                                            fieldList.push(
                                                [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                    <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                        {t(('FIELDS.' + property).toUpperCase())}:
                                                    </Box>
                                                </Grid>,
                                                <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                    <Text key={property + '-tag-text'} color="black">
                                                        <b>{['J', 'L', 'F', 'U', 'V'].filter(v => !props.item[property].includes(v))}</b>
                                                    </Text>
                                                </Grid>]
                                            );
                                            break;
                                        case 'dinghles':
                                            fieldList.push(
                                                [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                    <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                        {t(('FIELDS.' + property).toUpperCase())}:
                                                    </Box>
                                                </Grid>,
                                                <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                    <Text key={property + '-tag-text'} color="black">
                                                        <b>{props.item[property].length ? '' : 'D'}</b>
                                                    </Text>
                                                </Grid>]
                                            );
                                            break;
                                        case 'dinghles_cover':
                                            fieldList.push(
                                                [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                    <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                        {t(('FIELDS.' + property).toUpperCase())}:
                                                    </Box>
                                                </Grid>,
                                                <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                    <Text key={property + '-tag-text'} color="black">
                                                        <b>{props.item[property].length ? '' : 'C'}</b>
                                                    </Text>
                                                </Grid>]
                                            );
                                            break;

                                        case 'created_by':
                                            fieldList.push(
                                                [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                    <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                        {t(('FIELDS.' + property).toUpperCase())}:
                                                    </Box>
                                                </Grid>,
                                                <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                    <Text key={property + '-tag-text'} color="black">
                                                        <b>{props.item[property].pilot_profile.name}</b>
                                                    </Text>
                                                </Grid>]
                                            );
                                            break;

                                        default:
                                            fieldList.push(
                                                [<Grid key={property + '-tag'} item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                    <Box key={property + '-tag-text'} pr={3} pb={1}>
                                                        {t(('FIELDS.' + property).toUpperCase())}:
                                                    </Box>
                                                </Grid>,
                                                <Grid key={property + '-value'} item xs={12} sm={8} md={9}>
                                                    <Text key={property + '-tag-text'} color="black">
                                                        <b>{props.item[property]}</b>
                                                    </Text>
                                                </Grid>]
                                            );
                                            break;
                                    }
                                }
                            }
                            return fieldList;
                        })()}
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'space-evenly'}>
                        <Grid item xs={12} md={4}>
                            <Text color="black">
                                {t('FIELDS.AIRWORTHINESS_CERTIFICATE_PHOTO_URL')}
                            </Text>
                            {
                                props.item['airworthiness_certificate_photo_base_64'] &&
                                (props.item['airworthiness_certificate_photo_base_64']['mime'] != 'application/pdf' ?
                                    < Zoom >
                                        <img style={{ width: '90%' }} src={props.item['airworthiness_certificate_photo_base_64']['content']} />
                                    </Zoom>
                                    :
                                    <embed src={props.item['airworthiness_certificate_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }} />)
                            }
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Text color="black">
                                {t('FIELDS.INSURANCE_PHOTO_URL')}
                            </Text>
                            {
                                props.item['insurance_photo_base_64'] &&
                                (props.item['insurance_photo_base_64']['mime'] != 'application/pdf' ?
                                    < Zoom >
                                        <img style={{ width: '90%' }} src={props.item['insurance_photo_base_64']['content']} />
                                    </Zoom>
                                    :
                                    <embed src={props.item['insurance_photo_base_64']['content']} width="100%" style={{ minHeight: '400px' }}></embed>)
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </>
        )
    }

    return (
        <CrudBase
            resource="pilot-aircrafts"
            indexColumns={['plate', 'type_of_aircraft', 'aircraft_colour_and_markings']}
            form={PilotAircraftsForm}
            deleteButton={false}
            searchText={true}
            editButton={(user, item) => { return user.can('pilot-aircrafts' + '.edit') && item?.created_by.id == user.id }}
            icon={<AirplanemodeActiveIcon fontSize="large" />}
            CustomShow={CustomShow} />
    );
}
