import axios from 'axios';
import { parse } from "content-disposition-header";

const http = axios.create({
    baseURL: "https://api.sch.sokodev.com",
    headers: {
        "X-requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
});

http.interceptors.response.use(
    (response) => response,
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        switch (error.response.status) {
            case 403:
                if (error.response.data.exception != 'Illuminate\\Routing\\Exceptions\\InvalidSignatureException') {
                    window.location.href = '/status/403';
                }
                break;

            case 401:
                window.location.href = '/login';
                break;

            default:
                break;
        }

        return Promise.reject(
            (error.response && error.response.data) || 'There is an error!'
        )
    }
);

const downloadFile = (url, data?) => {
    return http.get(url, { responseType: 'blob', params: data }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = 'unknown';
        if (response.headers['content-disposition']) {
            const contentDisposition = parse(response.headers['content-disposition']);
            filename = contentDisposition.parameters.filename ?? filename;
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
}


const downloadFilePost = (url, data?) => {
    return http.post(url, data, { responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = 'unknown';
        if (response.headers['content-disposition']) {
            const contentDisposition = parse(response.headers['content-disposition']);
            filename = contentDisposition.parameters.filename ?? filename;
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
}

export default http;
export { downloadFile, downloadFilePost };
