import { useRoutes } from "react-router-dom";
import router from "src/router";

import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useAuth from "src/hooks/useAuth";
import ThemeProviderWrapper from "./theme/ThemeProvider";
import { Alert, Button, CssBaseline, Grid, Icon, IconButton, Snackbar, SnackbarContent, Stack, Typography } from "@mui/material";
import AppInit from "./components/AppInit";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import http from "./utils/httpHelper";

function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  const [notamsOpen, setNotamsOpen] = useState(true);
  const [notams, setNotams] = useState([]);
  const [notamsError, setNotamsError] = useState('');

  useEffect(() => {
    http.get('api/get-latest-notams')
      .then(response => setNotams(response.data))
      .catch(error => {
        setNotamsError(error.message);
      });
  }, [])

  const Notams = () => {
    return (
      <Stack spacing={2} sx={{ maxWidth: 600, position: "absolute", margin: '20px', zIndex: 100, marginBlock: '15px', padding: '15px', background: 'cornsilk', borderRadius: '10px' }}>
        <Grid item sm={12} md={12} display='flex' justifyContent={'space-between'}>
          <Typography>
            <h2 style={{ margin: 'unset' }}>Notams</h2>
          </Typography>
          <IconButton size="small" color="error" onClick={() => { setNotamsOpen(false) }}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </Grid>

        {
          notamsError ? <Alert severity={'error'}>Hubo un error al obtener las notams</Alert> :
            (notams.length == 0 ? <Alert severity={'info'}>Sin notams para mostrar</Alert> :
              notams.map(notam => <Alert key={notam.id} severity={notam.severity}>{notam.message}</Alert>))
        }
      </Stack>
    );
  }

  return (
    <ThemeProviderWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <CssBaseline />
          {notamsOpen && <Notams />}
          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProviderWrapper>
  );
}
export default App;
