import { Box, styled, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        align-items: center;
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 52px;
`
);

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
);

const VersionBadge = styled(Box)(
  ({ theme }) => `
        background: ${theme.palette.success.main};
        color: ${theme.palette.success.contrastText};
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(15)};
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.colors.secondary.light}
`
);

function Logo() {
  const { t }: { t: any } = useTranslation();

  return (
    <LogoWrapper to="/">
      <LogoSignWrapper>
        <img src="/logo.png" alt="" width={"100%"} />
      </LogoSignWrapper>
      {
        t('LOGO.VERSION_DESC') != '' && t('LOGO.VERSION') != '' && t('LOGO.SYSTEM_NAME') != '' &&
        <Box
          component="span"
        /* sx={{
          display: { xs: "none", sm: "inline-block" },
        }} */
        >
          <LogoTextWrapper>
            <Tooltip title={t('LOGO.VERSION_DESC')} arrow placement="right">
              <VersionBadge>{t('LOGO.VERSION')}</VersionBadge>
            </Tooltip>
            <LogoText>{t('LOGO.SYSTEM_NAME')}</LogoText>
          </LogoTextWrapper>
        </Box>
      }
    </LogoWrapper>
  );
}

export default Logo;
