import { useState, ReactElement, forwardRef, Ref } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Card,
    TextField,
    Typography,
    Container,
    Alert,
    Slide,
    Dialog,
    Collapse,
    Button,
    Avatar,
    IconButton,
    styled,
    CircularProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { TransitionProps } from '@mui/material/transitions';
import useRefMounted from 'src/hooks/useRefMounted';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import http from 'src/utils/httpHelper';
import { useSnackbar } from 'notistack';
import anyToString from 'src/utils/anyToString';

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
    () => `
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    `
);

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
    `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function ResetPassword() {
    const navigate = useNavigate();
    const { t }: { t: any } = useTranslation();
    const isMountedRef = useRefMounted();

    const [openAlert, setOpenAlert] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/login');
    };

    const { token } = useParams();

    return (
        <>
            <Helmet>
                <title>{t('RESET_PASSWORD.HEADING')}</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="sm">
                    <Card
                        sx={{
                            mt: 3,
                            p: 4
                        }}
                    >
                        <Box sx={{ pb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src="/logo.png" alt="" width={"50%"} />
                        </Box>
                        <Box>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: 1
                                }}
                            >
                                {t('RESET_PASSWORD.HEADING')}
                            </Typography>
                            <Typography
                                variant="h4"
                                color="text.secondary"
                                fontWeight="normal"
                                sx={{
                                    mb: 3
                                }}
                            >
                                {t('RESET_PASSWORD.SUBHEADING')}
                            </Typography>
                        </Box>

                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                password_confirmation: '',
                                token,
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email(
                                        t('VALIDATION.EMAIL')
                                    )
                                    .max(255)
                                    .required(t('VALIDATION.REQUIRED')),
                                password: Yup.string()
                                    .required(t('VALIDATION.REQUIRED')),
                                password_confirmation: Yup.string()
                                    .required(t('VALIDATION.REQUIRED'))
                            })}
                            onSubmit={async (
                                values,
                                { setErrors, setStatus, setSubmitting }
                            ) => {
                                await http.post('/reset-password', values)
                                    .then((response: any) => {
                                        if (isMountedRef.current) {
                                            setStatus({ success: true });
                                            handleOpenDialog();
                                        }
                                    })
                                    .catch((error) => {
                                        enqueueSnackbar(error.message, { variant: 'error' });
                                        console.error(error);
                                        if (isMountedRef.current) {
                                            setStatus({ success: false });
                                            setErrors({ submit: error.message });
                                        }
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({
                                errors,
                                handleSubmit,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                touched,
                                values
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <TextField
                                        autoFocus
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email && errors.email.toString()}
                                        label={t('FIELDS.EMAIL')}
                                        margin="normal"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="email"
                                        value={values.email}
                                        variant="outlined"
                                    />

                                    <TextField
                                        error={Boolean(touched.password && errors.password)}
                                        fullWidth
                                        helperText={touched.password && errors.password && errors.password.toString()}
                                        label={t('FIELDS.PASSWORD')}
                                        margin="normal"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password"
                                        value={values.password}
                                        variant="outlined"
                                    />

                                    <TextField
                                        error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                                        fullWidth
                                        helperText={touched.password_confirmation && errors.password_confirmation && errors.password_confirmation.toString()}
                                        label={t('FIELDS.PASSWORD_CONFIRMATION')}
                                        margin="normal"
                                        name="password_confirmation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password"
                                        value={values.password_confirmation}
                                        variant="outlined"
                                    />

                                    <Button
                                        sx={{
                                            mt: 3
                                        }}
                                        color="primary"
                                        startIcon={
                                            isSubmitting ? <CircularProgress size="1rem" /> : null
                                        }
                                        disabled={Boolean(errors.submit) || isSubmitting}
                                        type="submit"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('RESET_PASSWORD.HEADING')}
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </Card>
                </Container>
            </MainContent>

            <DialogWrapper
                open={openDialog}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
            >
                <Box
                    sx={{
                        px: 4,
                        pb: 4,
                        pt: 10
                    }}
                >
                    <AvatarSuccess>
                        <CheckTwoToneIcon />
                    </AvatarSuccess>

                    <Collapse in={openAlert}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity="info"
                        >
                            {t('RESET_PASSWORD.INSTRUCTIONS')}
                        </Alert>
                    </Collapse>

                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 10
                        }}
                        variant="h3"
                    >
                        {t('RESET_PASSWORD.SUCCESS')}
                    </Typography>

                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={handleCloseDialog}
                        href="/login"
                    >
                        {t('RESET_PASSWORD.TO_LOGIN')}
                    </Button>
                </Box>
            </DialogWrapper>
        </>
    );
}

export default ResetPassword;
