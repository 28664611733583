export type UserRole = 'admin' | 'customer' | 'subscriber';

export class User {
  id: string;
  username: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  active: number;
  role_id: number;
  role_name: string;
  permissions: string[];

  constructor(userData) {
    Object.assign(this, userData);
  }

  can = (permission: string) => {
    return this.permissions.includes(permission);
  };
}
