import { prettyPrint } from "@base2/pretty-print-object";
import moment from "moment"
import { Fragment } from "react";
import Moment from "react-moment"

const isStringsArray = arr => arr.every(i => typeof i === "string");
const anyToString = (value) => (<Fragment>
    {
        (() => {
            if (moment(value, moment.ISO_8601, true).isValid()) {
                return <Moment format="DD/MM/YYYY HH:mm:ss">{value}</Moment>
            } else if (Array.isArray(value) && isStringsArray(value)) {
                return <b>{value.join(', ')}</b>;
            } else if (typeof value === 'object' && value !== null) {
                return <pre>{prettyPrint(value)}</pre>;
            } else {
                return value;
            }
        })()
    }
</Fragment>);

export default anyToString;
