import { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import {
  Avatar,
  Box,
  IconButton,
  Popover,
  styled,
} from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import HeaderUserboxPopoverContent from "src/layouts/common/HeaderUserboxPopover";

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    display: inline-flex;
    border-radius: ${theme.general.borderRadiusLg};
    height: 58px;
    justify-content: center;
    font-size: ${theme.typography.pxToRem(13)};
    position: relative;
    margin-left: ${theme.spacing(1)};

    .MuiSvgIcon-root {
      transition: ${theme.transitions.create(["color"])};
      font-size: ${theme.typography.pxToRem(16)};
      color: ${theme.colors.alpha.trueWhite[50]};
    }

    &.Mui-active,
    &:hover {
      background-color: ${theme.colors.alpha.black[10]};

      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
`
);

function Userbox() {
  const { user } = useAuth();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.username} src={"/static/images/placeholders/avatar.png"} />
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1,
            }}
          />
        </Box>
      </IconButtonPrimary>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <HeaderUserboxPopoverContent closeCallBack={handleClose}></HeaderUserboxPopoverContent>
      </Popover>
    </>
  );
}

export default Userbox;
