import { FC } from "react";

interface FlightPlanPdfRenderProps {
    item: any
}

const FlightPlanPdfRender: FC<FlightPlanPdfRenderProps> = ({ item }) => {
    return (
        <embed src={"https://api.sch.sokodev.com/api/flight-plans/" + item.id + '/generatePdf'} width="100%" height="700px"></embed>
    );
}

export default FlightPlanPdfRender;