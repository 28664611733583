import { useState, useEffect } from 'react';
import {
    Box,
    Card,
    Typography,
    Container,
    styled,
    CircularProgress,
    Button,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import http from 'src/utils/httpHelper';
import useAuth from 'src/hooks/useAuth';
import { User } from 'src/models/user';
import { useParams } from 'react-router-dom';
import MailLockIcon from '@mui/icons-material/MailLock';
import Grow from '@mui/material/Grow';

const MainContent = styled(Box)(
    () => `
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    `
);

function VerifyEmail() {
    const { t }: { t: any } = useTranslation();
    const [verificationError, setVerificationError] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
    const { id, token, expires, signature } = useParams();
    const { setUser } = useAuth();

    useEffect(() => {
        http.get(`/email/verify/${id}/${token}`, { params: { expires, signature: signature } })
            .then(async (response: any) => {
                await setUser(new User(response));
                setResponseMessage(t('VERIFY_EMAIL.VERIFIED'));
                setVerificationError(false);
                setTimeout(() => {
                    window.location.href = '/';
                }, 2500)
            })
            .catch((error) => {
                setResponseMessage(t('VERIFY_EMAIL.VERIFIED_ERROR'));
                setVerificationError(true);
                console.error(error);
            })
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('VERIFY_EMAIL.HEADING')}</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="sm">
                    <Card
                        sx={{
                            mt: 3,
                            p: 4
                        }}
                    >
                        <Box sx={{ pb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MailLockIcon style={{ zoom: 8 }} />
                        </Box>
                        <Box>
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                {
                                    !responseMessage &&
                                    <>
                                        <CircularProgress />
                                        <Typography
                                            variant="h3"
                                            color={'primary'}
                                            fontWeight="normal"
                                            sx={{
                                                ml: 2
                                            }}
                                        >
                                            {t('VERIFY_EMAIL.VERIFYING')}
                                        </Typography>
                                    </>
                                }
                                {
                                    responseMessage &&
                                    <Grow in={!!responseMessage} timeout={750}>
                                        <Typography
                                            variant="h3"
                                            color={verificationError ? 'error' : 'success.main'}
                                            fontWeight="normal"
                                            sx={{
                                                ml: 2
                                            }}
                                        >
                                            {responseMessage}
                                        </Typography>
                                    </Grow>
                                }
                            </Box>
                            {
                                verificationError &&
                                <Grow in={!!responseMessage} timeout={750}>
                                    <Button
                                        href='/status/403/email'
                                        sx={{
                                            mt: 3
                                        }}
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('VERIFY_EMAIL.BUTTON')}
                                    </Button>
                                </Grow>
                            }
                        </Box>
                    </Card>
                </Container>
            </MainContent>
        </>
    );
}

export default VerifyEmail;
