import CrudBase from "src/components/CrudBase";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import NotamsForm from "./NotamsForm";
import { t } from "i18next";

export function Notams() {
    return (
        <CrudBase
            resource="notams"
            indexColumns={['message', 'init_date', 'end_date', 'severity']}
            customColumns={{
                'severity': (severity) => t('NOTAMS.SEVERITY.' + severity)
            }}
            icon={<StickyNote2Icon fontSize="large" />}
            form={NotamsForm} />
    );
}
