import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Grid,
    Box,
    TextField,
    CircularProgress,
    Avatar,
    IconButton,
    Button,
    styled,
    Autocomplete,
    FormHelperText,
    FormLabel
} from '@mui/material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { useTranslation } from 'react-i18next';
import http from 'src/utils/httpHelper';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import Zoom from 'react-medium-image-zoom';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';

const Input = styled('input')({
    display: 'none'
});

const AvatarWrapper = styled(Box)(
    ({ theme }) => `
        position: relative;
        width: 75%;
        .MuiAvatar-root {
            width: ${theme.spacing(35)};
            height: ${theme.spacing(15)};
        }
        .MuiAvatar-rounded {
            width: 100%;
            height: 250px;
        }
        img {
            object-fit: scale-down;
        }
  `
);

const ButtonUploadWrapper = styled(Box)(
    ({ theme }) => `
        position: absolute;
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        bottom: -${theme.spacing(2)};
        right: -${theme.spacing(2)};

        .MuiIconButton-root {
            border-radius: 100%;
            background: ${theme.colors.primary.main};
            color: ${theme.palette.primary.contrastText};
            box-shadow: ${theme.colors.shadows.primary};
            width: ${theme.spacing(6)};
            height: ${theme.spacing(6)};
            padding: 0;

            &:hover {
                background: ${theme.colors.primary.dark};
            }
        }
    `
);
const rebuildData = (values, edit = false) => {
    let formData = new FormData();
    Object.keys(values).forEach(key => {
        if (values[key]) {
            formData.append(key, values[key]);
        }
    });
    if (edit) {
        formData.append('_method', 'PUT');
    }
    return formData;
};

function PilotInfo() {
    const { t } = useTranslation();
    const [pilotLicencePhotoPreview, setPilotLicencePhotoPreview] = useState(null);
    const [pilotLicenceReversePhotoPreview, setPilotLicenceReversePhotoPreview] = useState(null);
    const [pilotUsaLicencePhotoPreview, setPilotUsaLicencePhotoPreview] = useState(null);
    const [pilotUsaLicenceReversePhotoPreview, setPilotUsaLicenceReversePhotoPreview] = useState(null);
    const [inePhotoPreview, setInePhotoPreview] = useState(null);
    const [healthTestPhotoPreview, setHealthTestPhotoPreview] = useState(null);
    const [pilotProfile, setPilotProfile] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [operationBases, setOperationBases] = useState([]);

    useEffect(() => {
        http.get('/api/pilot-profiles/get-user-pilot-profile', {
            params: { 'user_id': user.id }
        })
            .then(response => {
                setPilotProfile(response.data);
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, { variant: 'error' });
            });
        http.get('/api/get-operation-bases-for-select')
            .then(response => {
                setOperationBases(response.data);
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }, []);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: pilotProfile ? pilotProfile.name : '',
                    cellphone: pilotProfile ? pilotProfile.cellphone : '',
                    licence_number: pilotProfile ? pilotProfile.licence_number : '',
                    operation_base: pilotProfile ? pilotProfile.operation_base : '',
                    address: pilotProfile ? pilotProfile.address : '',
                    pilot_licence_photo: null,
                    pilot_licence_reverse_photo: null,
                    pilot_usa_licence_photo: null,
                    pilot_usa_licence_reverse_photo: null,
                    ine_photo: null,
                    health_test_photo: null,
                    copilot_name: pilotProfile ? (pilotProfile.copilot_name ?? '') : '',
                    copilot_licence_number: pilotProfile ? (pilotProfile.copilot_licence_number ?? '') : '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .max(191)
                        .required(t('VALIDATION.REQUIRED')),
                    cellphone: Yup.string()
                        .max(191)
                        .required(t('VALIDATION.REQUIRED')),
                    licence_number: Yup.string()
                        .max(191)
                        .required(t('VALIDATION.REQUIRED')),
                    operation_base: Yup.string()
                        .max(191)
                        .required(t('VALIDATION.REQUIRED')),
                    address: Yup.string()
                        .max(191)
                        .required(t('VALIDATION.REQUIRED')),
                    pilot_licence_photo: pilotProfile ? null : Yup.mixed(),
                    pilot_licence_reverse_photo: pilotProfile ? null : Yup.mixed(),
                    ine_photo: pilotProfile ? null : Yup.mixed(),
                    health_test_photo: pilotProfile ? null : Yup.mixed(),
                    copilot_name: Yup.string()
                        .max(191),
                    copilot_licence_number: Yup.string()
                        .max(191)
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (pilotProfile) {
                        http.post('/api/pilot-profiles/' + pilotProfile.id, rebuildData(_values, true))
                            .then((response: any) => {
                                setPilotProfile(response.data);
                                setStatus({ success: true });
                                resetForm();
                                enqueueSnackbar(t('PILOT_PROFILE.UPDATED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        http.post('/api/pilot-profiles', rebuildData(_values))
                            .then((response: any) => {
                                setPilotProfile(response.data);
                                setStatus({ success: true });
                                resetForm();
                                enqueueSnackbar(t('PILOT_PROFILE.SAVED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    setTouched
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.name && errors.name)}
                                            fullWidth
                                            helperText={touched.name && errors.name && errors.name.toString()}
                                            label={t('FIELDS.NAME')}
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(
                                                touched.cellphone && errors.cellphone
                                            )}
                                            fullWidth
                                            helperText={touched.cellphone && errors.cellphone && errors.cellphone.toString()}
                                            label={t('FIELDS.CELLPHONE')}
                                            name="cellphone"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.cellphone}
                                            variant="outlined"
                                            type={'number'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.licence_number && errors.licence_number)}
                                            fullWidth
                                            helperText={touched.licence_number && errors.licence_number && errors.licence_number.toString()}
                                            label={t('FIELDS.LICENCE_NUMBER')}
                                            name="licence_number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.licence_number}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            options={operationBases}
                                            freeSolo={true}
                                            onChange={(_, option: any) => setFieldValue('operation_base', option ?? '')}
                                            value={values.operation_base}
                                            renderInput={props => (
                                                <TextField
                                                    {...props}
                                                    label={t('FIELDS.OPERATION_BASE')}
                                                    error={Boolean(touched.operation_base && errors.operation_base)}
                                                    helperText={touched.operation_base && errors.operation_base && errors.operation_base.toString()}
                                                    onChange={handleChange}
                                                    name={'operation_base'}
                                                    onBlur={handleBlur}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.address && errors.address)}
                                            fullWidth
                                            helperText={touched.address && errors.address && errors.address.toString()}
                                            label={t('FIELDS.ADDRESS')}
                                            name="address"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.address}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12} justifyContent="center">
                                <Grid container>
                                    <Grid item sm={12} md={6} lg={6} mt={4}>
                                        <FormLabel>{t('FIELDS.PILOT_LICENCE_PHOTO')}</FormLabel>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="row"
                                            mt={3}
                                        >
                                            <AvatarWrapper>
                                                {(pilotProfile?.pilot_licence_photo_base_64?.mime != 'application/pdf' && pilotLicencePhotoPreview?.mime != 'application/pdf') ?
                                                    <Zoom>
                                                        <Avatar
                                                            style={{ display: 'grid', overflow: 'overlay' }}
                                                            variant="rounded"
                                                            alt={'placeholder'}
                                                            src={pilotLicencePhotoPreview ? pilotLicencePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_licence_photo_base_64?.content : null)}
                                                        >{t('FIELDS.PILOT_LICENCE_PHOTO')}</Avatar>
                                                    </Zoom>
                                                    : <object
                                                        width={'100%'}
                                                        height={'350px'}
                                                        data={pilotLicencePhotoPreview ? pilotLicencePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_licence_photo_base_64?.content : null)}
                                                    />
                                                }
                                                {
                                                    (!pilotProfile?.pilot_licence_photo_base_64?.content && !pilotLicencePhotoPreview?.content) ?
                                                        <ButtonUploadWrapper>
                                                            <Input
                                                                accept="application/pdf, image/*"
                                                                id="pilot-licence-icon-button-file"
                                                                type="file"
                                                                onBlur={() => setTouched({ ['pilot_licence_photo']: true })}
                                                                onChange={(event) => {
                                                                    const file = event.currentTarget.files[0];
                                                                    setFieldValue('pilot_licence_photo', file);
                                                                    setFieldValue('delete_pilot_licence_photo', null);
                                                                    const reader = new FileReader();
                                                                    reader.onloadend = () => {
                                                                        setPilotLicencePhotoPreview({ content: reader.result, mime: file.type });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                            <label htmlFor="pilot-licence-icon-button-file">
                                                                <IconButton component="span" color="secondary">
                                                                    <CloudUploadTwoToneIcon />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper> :
                                                        <ButtonUploadWrapper>
                                                            <label htmlFor="pilot-licence-icon-button-file">
                                                                <IconButton component="span" color="error" onClick={
                                                                    () => {
                                                                        setPilotLicencePhotoPreview(null);
                                                                        setFieldValue('pilot_licence_photo', null);
                                                                        setPilotProfile({ ...pilotProfile, pilot_licence_photo_base_64: '' });
                                                                        setFieldValue('delete_pilot_licence_photo', 'true');
                                                                    }
                                                                }>
                                                                    <DeleteTwoTone />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper>
                                                }
                                            </AvatarWrapper>
                                        </Box>
                                        <FormHelperText sx={{ textAlign: 'center' }} error>
                                            {touched.pilot_licence_photo && errors.pilot_licence_photo && errors.pilot_licence_photo.toString()}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={6} mt={4}>
                                        <FormLabel>{t('FIELDS.PILOT_LICENCE_REVERSE_PHOTO')}</FormLabel>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="row"
                                            mt={3}
                                        >
                                            <AvatarWrapper>
                                                {(pilotProfile?.pilot_licence_reverse_photo_base_64?.mime != 'application/pdf' && pilotLicenceReversePhotoPreview?.mime != 'application/pdf') ?
                                                    <Zoom>
                                                        <Avatar
                                                            style={{ display: 'grid', overflow: 'overlay' }}
                                                            variant="rounded"
                                                            alt={'placeholder'}
                                                            src={pilotLicenceReversePhotoPreview ? pilotLicenceReversePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_licence_reverse_photo_base_64?.content : null)}
                                                        >{t('FIELDS.PILOT_LICENCE_REVERSE_PHOTO')}</Avatar>
                                                    </Zoom>
                                                    : <object
                                                        width={'100%'}
                                                        height={'350px'}
                                                        data={pilotLicenceReversePhotoPreview ? pilotLicenceReversePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_licence_reverse_photo_base_64?.content : null)}
                                                    />
                                                }
                                                {
                                                    (!pilotProfile?.pilot_licence_reverse_photo_base_64?.content && !pilotLicenceReversePhotoPreview?.content) ?
                                                        <ButtonUploadWrapper>
                                                            <Input
                                                                accept="application/pdf, image/*"
                                                                id="pilot-licence-reverse-icon-button-file"
                                                                type="file"
                                                                onBlur={() => setTouched({ ['pilot_licence_reverse_photo']: true })}
                                                                onChange={(event) => {
                                                                    const file = event.currentTarget.files[0];
                                                                    setFieldValue('delete_pilot_licence_reverse_photo', null);
                                                                    setFieldValue('pilot_licence_reverse_photo', file);
                                                                    const reader = new FileReader();
                                                                    reader.onloadend = () => {
                                                                        setPilotLicenceReversePhotoPreview({ content: reader.result, mime: file.type });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                            <label htmlFor="pilot-licence-reverse-icon-button-file">
                                                                <IconButton component="span" color="info">
                                                                    <CloudUploadTwoToneIcon />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper> :
                                                        <ButtonUploadWrapper>
                                                            <label htmlFor="pilot-licence-reverse-icon-button-file">
                                                                <IconButton component="span" color="error" onClick={
                                                                    () => {
                                                                        setPilotLicenceReversePhotoPreview(null);
                                                                        setFieldValue('pilot_licence_reverse_photo', null);
                                                                        setPilotProfile({ ...pilotProfile, pilot_licence_reverse_photo_base_64: '' });
                                                                        setFieldValue('delete_pilot_licence_reverse_photo', 'true');
                                                                    }
                                                                }>
                                                                    <DeleteTwoTone />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper>
                                                }
                                            </AvatarWrapper>
                                        </Box>
                                        <FormHelperText sx={{ textAlign: 'center' }} error>
                                            {touched.pilot_licence_reverse_photo && errors.pilot_licence_reverse_photo && errors.pilot_licence_reverse_photo.toString()}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={6} mt={4}>
                                        <FormLabel>{t('FIELDS.INE_PHOTO')}</FormLabel>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="row"
                                            mt={3}
                                        >
                                            <AvatarWrapper>
                                                {(pilotProfile?.ine_photo_base_64?.mime != 'application/pdf' && inePhotoPreview?.mime != 'application/pdf') ?
                                                    <Zoom>
                                                        <Avatar
                                                            style={{ display: 'grid', overflow: 'overlay' }}
                                                            variant="rounded"
                                                            alt={'placeholder'}
                                                            src={inePhotoPreview ? inePhotoPreview?.content : (pilotProfile ? pilotProfile.ine_photo_base_64?.content : null)}
                                                        >{t('FIELDS.INE_PHOTO')}</Avatar>
                                                    </Zoom>
                                                    : <object
                                                        width={'100%'}
                                                        height={'350px'}
                                                        data={inePhotoPreview ? inePhotoPreview?.content : (pilotProfile ? pilotProfile.ine_photo_base_64?.content : null)}
                                                    />
                                                }
                                                {
                                                    (!pilotProfile?.ine_photo_base_64?.content && !inePhotoPreview?.content) ?
                                                        <ButtonUploadWrapper>
                                                            <Input
                                                                accept="application/pdf, image/*"
                                                                id="ine-icon-button-file"
                                                                type="file"
                                                                onBlur={() => setTouched({ ['ine_photo']: true })}
                                                                onChange={(event) => {
                                                                    const file = event.currentTarget.files[0];
                                                                    setFieldValue('delete_ine_photo', null);
                                                                    setFieldValue('ine_photo', file);
                                                                    const reader = new FileReader();
                                                                    reader.onloadend = () => {
                                                                        setInePhotoPreview({ content: reader.result, mime: file.type });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                            <label htmlFor="ine-icon-button-file">
                                                                <IconButton component="span" color="secondary">
                                                                    <CloudUploadTwoToneIcon />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper> :
                                                        <ButtonUploadWrapper>
                                                            <label htmlFor="ine-icon-button-file">
                                                                <IconButton component="span" color="error" onClick={
                                                                    () => {
                                                                        setInePhotoPreview(null);
                                                                        setFieldValue('ine_photo', null);
                                                                        setPilotProfile({ ...pilotProfile, ine_photo_base_64: '' });
                                                                        setFieldValue('delete_ine_photo', 'true');
                                                                    }
                                                                }>
                                                                    <DeleteTwoTone />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper>
                                                }
                                            </AvatarWrapper>
                                        </Box>
                                        <FormHelperText sx={{ textAlign: 'center' }} error>
                                            {touched.ine_photo && errors.ine_photo && errors.ine_photo.toString()}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={6} mt={4}>
                                        <FormLabel>{t('FIELDS.HEALTH_TEST_PHOTO')}</FormLabel>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="row"
                                            mt={3}
                                        >
                                            <AvatarWrapper>
                                                {(pilotProfile?.health_test_photo_base_64?.mime != 'application/pdf' && healthTestPhotoPreview?.mime != 'application/pdf') ?
                                                    <Zoom>
                                                        <Avatar
                                                            style={{ display: 'grid', overflow: 'overlay' }}
                                                            variant="rounded"
                                                            alt={'placeholder'}
                                                            src={healthTestPhotoPreview ? healthTestPhotoPreview?.content : (pilotProfile ? pilotProfile.health_test_photo_base_64?.content : null)}
                                                        >{t('FIELDS.HEALTH_TEST_PHOTO')}</Avatar>
                                                    </Zoom>
                                                    : <object
                                                        width={'100%'}
                                                        height={'350px'}
                                                        data={healthTestPhotoPreview ? healthTestPhotoPreview?.content : (pilotProfile ? pilotProfile.health_test_photo_base_64?.content : null)}
                                                    />
                                                }
                                                {
                                                    (!pilotProfile?.health_test_photo_base_64?.content && !healthTestPhotoPreview?.content) ?
                                                        <ButtonUploadWrapper>
                                                            <Input
                                                                accept="application/pdf, image/*"
                                                                id="health-test-icon-button-file"
                                                                type="file"
                                                                onBlur={() => setTouched({ ['health_test_photo']: true })}
                                                                onChange={(event) => {
                                                                    const file = event.currentTarget.files[0];
                                                                    setFieldValue('delete_health_test_photo', null);
                                                                    setFieldValue('health_test_photo', file);
                                                                    const reader = new FileReader();
                                                                    reader.onloadend = () => {
                                                                        setHealthTestPhotoPreview({ content: reader.result, mime: file.type });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                            <label htmlFor="health-test-icon-button-file">
                                                                <IconButton component="span" color="secondary">
                                                                    <CloudUploadTwoToneIcon />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper> :
                                                        <ButtonUploadWrapper>
                                                            <label htmlFor="health-test-icon-button-file">
                                                                <IconButton component="span" color="error" onClick={
                                                                    () => {
                                                                        setHealthTestPhotoPreview(null);
                                                                        setFieldValue('health_test_photo', null);
                                                                        setPilotProfile({ ...pilotProfile, health_test_photo_base_64: '' });
                                                                        setFieldValue('delete_health_test_photo', 'true');
                                                                    }
                                                                }>
                                                                    <DeleteTwoTone />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper>
                                                }
                                            </AvatarWrapper>
                                        </Box>
                                        <FormHelperText sx={{ textAlign: 'center' }} error>
                                            {touched.health_test_photo && errors.health_test_photo && errors.health_test_photo.toString()}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={6} mt={4}>
                                        <FormLabel>{t('FIELDS.PILOT_USA_LICENCE_PHOTO')}</FormLabel>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="row"
                                            mt={3}
                                        >
                                            <AvatarWrapper>
                                                {(pilotProfile?.pilot_usa_licence_photo_base_64?.mime != 'application/pdf' && pilotUsaLicencePhotoPreview?.mime != 'application/pdf') ?
                                                    <Zoom>
                                                        <Avatar
                                                            style={{ display: 'grid', overflow: 'overlay' }}
                                                            variant="rounded"
                                                            alt={'placeholder'}
                                                            src={pilotUsaLicencePhotoPreview ? pilotUsaLicencePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_usa_licence_photo_base_64?.content : null)}
                                                        >{t('FIELDS.PILOT_USA_LICENCE_PHOTO')}</Avatar>
                                                    </Zoom>
                                                    : <object
                                                        width={'100%'}
                                                        height={'350px'}
                                                        data={pilotUsaLicencePhotoPreview ? pilotUsaLicencePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_usa_licence_photo_base_64?.content : null)}
                                                    />
                                                }
                                                {
                                                    (!pilotProfile?.pilot_usa_licence_photo_base_64?.content && !pilotUsaLicencePhotoPreview?.content) ?
                                                        <ButtonUploadWrapper>
                                                            <Input
                                                                accept="application/pdf, image/*"
                                                                id="pilot-usa-licence-icon-button-file"
                                                                type="file"
                                                                onChange={(event) => {
                                                                    const file = event.currentTarget.files[0];
                                                                    setFieldValue('delete_pilot_usa_licence_photo', null);
                                                                    setFieldValue('pilot_usa_licence_photo', file);
                                                                    const reader = new FileReader();
                                                                    reader.onloadend = () => {
                                                                        setPilotUsaLicencePhotoPreview({ content: reader.result, mime: file.type });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                            <label htmlFor="pilot-usa-licence-icon-button-file">
                                                                <IconButton component="span" color="secondary">
                                                                    <CloudUploadTwoToneIcon />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper> :
                                                        <ButtonUploadWrapper>
                                                            <label htmlFor="pilot-usa-licence-icon-button-file">
                                                                <IconButton component="span" color="error" onClick={
                                                                    () => {
                                                                        setPilotUsaLicencePhotoPreview(null);
                                                                        setFieldValue('pilot_usa_licence_photo', null);
                                                                        setPilotProfile({ ...pilotProfile, pilot_usa_licence_photo_base_64: '' });
                                                                        setFieldValue('delete_pilot_usa_licence_photo', 'true');
                                                                    }
                                                                }>
                                                                    <DeleteTwoTone />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper>
                                                }
                                            </AvatarWrapper>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={6} mt={4}>
                                        <FormLabel>{t('FIELDS.PILOT_USA_LICENCE_REVERSE_PHOTO')}</FormLabel>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="row"
                                            mt={3}
                                        >
                                            <AvatarWrapper>
                                                {(pilotProfile?.pilot_usa_licence_reverse_photo_base_64?.mime != 'application/pdf' && pilotUsaLicenceReversePhotoPreview?.mime != 'application/pdf') ?
                                                    <Zoom>
                                                        <Avatar
                                                            style={{ display: 'grid', overflow: 'overlay' }}
                                                            variant="rounded"
                                                            alt={'placeholder'}
                                                            src={pilotUsaLicenceReversePhotoPreview ? pilotUsaLicenceReversePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_usa_licence_reverse_photo_base_64?.content : null)}
                                                        >{t('FIELDS.PILOT_USA_LICENCE_REVERSE_PHOTO')}</Avatar>
                                                    </Zoom>
                                                    : <object
                                                        width={'100%'}
                                                        height={'350px'}
                                                        data={pilotUsaLicenceReversePhotoPreview ? pilotUsaLicenceReversePhotoPreview?.content : (pilotProfile ? pilotProfile.pilot_usa_licence_reverse_photo_base_64?.content : null)}
                                                    />
                                                }
                                                {
                                                    (!pilotProfile?.pilot_usa_licence_reverse_photo_base_64?.content && !pilotUsaLicenceReversePhotoPreview?.content) ?
                                                        <ButtonUploadWrapper>
                                                            <Input
                                                                accept="application/pdf, image/*"
                                                                id="pilot-usa-licence-reverse-icon-button-file"
                                                                type="file"
                                                                onChange={(event) => {
                                                                    const file = event.currentTarget.files[0];
                                                                    setFieldValue('delete_pilot_usa_licence_reverse_photo', null);
                                                                    setFieldValue('pilot_usa_licence_reverse_photo', file);
                                                                    const reader = new FileReader();
                                                                    reader.onloadend = () => {
                                                                        setPilotUsaLicenceReversePhotoPreview({ content: reader.result, mime: file.type });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                            <label htmlFor="pilot-usa-licence-reverse-icon-button-file">
                                                                <IconButton component="span" color="secondary">
                                                                    <CloudUploadTwoToneIcon />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper> :
                                                        <ButtonUploadWrapper>
                                                            <label htmlFor="pilot-usa-licence-reverse-icon-button-file">
                                                                <IconButton component="span" color="error" onClick={
                                                                    () => {
                                                                        setPilotUsaLicenceReversePhotoPreview(null);
                                                                        setFieldValue('pilot_usa_licence_reverse_photo', null);
                                                                        setPilotProfile({ ...pilotProfile, pilot_usa_licence_reverse_photo_base_64: '' });
                                                                        setFieldValue('delete_pilot_usa_licence_reverse_photo', 'true');
                                                                    }
                                                                }>
                                                                    <DeleteTwoTone />
                                                                </IconButton>
                                                            </label>
                                                        </ButtonUploadWrapper>
                                                }
                                            </AvatarWrapper>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.copilot_name && errors.copilot_name)}
                                            fullWidth
                                            helperText={touched.copilot_name && errors.copilot_name && errors.copilot_name.toString()}
                                            label={t('FIELDS.COPILOT_NAME')}
                                            name="copilot_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.copilot_name}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(
                                                touched.copilot_licence_number && errors.copilot_licence_number
                                            )}
                                            fullWidth
                                            helperText={touched.copilot_licence_number && errors.copilot_licence_number && errors.copilot_licence_number.toString()}
                                            label={t('FIELDS.COPILOT_LICENCE_NUMBER')}
                                            name="copilot_licence_number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.copilot_licence_number}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} md={12} justifyContent='end' display={'flex'}>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t('PILOT_PROFILE.SAVE')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default PilotInfo;
