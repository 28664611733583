import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    FormControlLabel,
    FormLabel,
    FormGroup,
    FormHelperText
} from "@mui/material";
import { useSnackbar } from "notistack";
import Checkbox from '@mui/material/Checkbox';
import http from "src/utils/httpHelper";
import Text from "src/components/Text";
import anyToString from "src/utils/anyToString";

interface RolesFormProps {
    modalCloseCallback,
    onSuccessCallback,
    data?: any
}

const RolesForm: FC<RolesFormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        http.get('api/permissions/for-select')
            .then(response => setPermissions(response.data))
            .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    name: data ? data.name : "",
                    description: data ? data.description : "",
                    permissions: data ? data.permissions.map(permission => permission.id + "") : [],
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .max(191)
                        .required(t("VALIDATION.REQUIRED")),
                    description: Yup.string()
                        .max(191)
                        .required(t("VALIDATION.REQUIRED")),
                    permissions: Yup.array().test({
                        name: "categories_test",
                        exclusive: true,
                        message: t('VALIDATION.MIN_ONE'),
                        test: (value) => value.length > 0,
                    })
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        await http.put('api/roles/' + data.id, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(t('ROLES.EDITED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        await http.post('api/roles', _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback()
                                enqueueSnackbar(t('ROLES.SAVED'), { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name && errors.name.toString()}
                                        label={t("FIELDS.NAME")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.description && errors.description
                                        )}
                                        fullWidth
                                        helperText={touched.description && errors.description && errors.description.toString()}
                                        label={t("FIELDS.DESCRIPTION")}
                                        name="description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.description}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Text color="primary">{t('ROLES.PERMISSIONS')}</Text>
                                </Grid>
                                {permissions.map((permissionGroup) => (
                                    <Grid item xs={12} md={4} key={permissionGroup.id}>
                                        <FormLabel component="legend">{permissionGroup.name}</FormLabel>
                                        <FormGroup>
                                            {permissionGroup.permissions.map((permission) => (
                                                <FormControlLabel key={permission.id}
                                                    control={
                                                        <Checkbox onChange={handleChange} name="permissions" checked={values.permissions.includes(permission.id + "")} value={permission.id} />
                                                    }
                                                    label={permission.name}
                                                />)
                                            )}
                                        </FormGroup>
                                        {
                                            Boolean(touched.permissions && errors.permissions) &&
                                            <FormHelperText error={true}>{touched.permissions && errors.permissions && errors.permissions.toString()}</FormHelperText>
                                        }
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                            }}
                        >
                            <Button color="secondary" onClick={modalCloseCallback}>
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={Boolean(errors.submit) || isSubmitting}
                                variant="contained"
                            >
                                {t(!data ? "ROLES.CREATE" : 'ROLES.EDIT')}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default RolesForm;