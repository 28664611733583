import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Text from "src/components/Text";
import { useTranslation } from "react-i18next";
import { User } from "src/models/user";
import LockResetIcon from '@mui/icons-material/LockReset';
import { FC, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import EditProfileFormDialog from './EditProfileFormDialog';
import ChangePasswordDialog from "./ChangePasswordDialog";

interface EditProfileProps {
    user: User;
}

const EditProfile: FC<EditProfileProps> = ({ user }) => {
    const { t } = useTranslation();

    const [isEditFormOpen, setEditFormOpen] = useState(false);
    const toggleEditFormDialog = () => {
        setEditFormOpen(!isEditFormOpen);
    };

    const [isChangePasswordFormOpen, setChangePasswordFormOpen] = useState(false);
    const toggleChangePasswordForm = () => {
        setChangePasswordFormOpen(!isChangePasswordFormOpen);
    };

    return (
        <>
            <Card>
                <Grid
                    container
                    p={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item sm={8}>
                        <Typography variant="h4" gutterBottom>
                            {t('MY_ACCOUNT.USER_DETAILS')}
                            <Button
                                variant="text"
                                startIcon={<EditTwoToneIcon />}
                                onClick={toggleEditFormDialog}
                                size="small"
                            >
                                {t('MY_ACCOUNT.BTN_EDIT')}
                            </Button>
                        </Typography>
                        <Typography variant="subtitle2">
                            {t('MY_ACCOUNT.USER_DETAILS_DESCRIPTION')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}
                        sx={{
                            mt: { xs: 2, sm: 0 },
                        }}>
                        <Button
                            variant="outlined"
                            startIcon={<LockResetIcon />}
                            onClick={toggleChangePasswordForm}
                            fullWidth
                        >
                            {t('MY_ACCOUNT.BTN_CHANGE_PASSWORD')}
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <CardContent
                    sx={{
                        p: 4
                    }}
                >
                    <Typography variant="subtitle2">
                        <Grid container>
                            <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                                <Box pr={3}>
                                    {t('FIELDS.NAME')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={10}>
                                <Text color="black">
                                    <b>{user.username}</b>
                                </Text>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                                <Box pr={3}>
                                    {t('FIELDS.EMAIL')}:
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={10}>
                                <Text color="black">
                                    <b>{user.email}</b>
                                </Text>
                            </Grid>
                        </Grid>
                    </Typography>
                </CardContent>
            </Card>
            <EditProfileFormDialog isOpen={isEditFormOpen} onClose={toggleEditFormDialog} />
            <ChangePasswordDialog isOpen={isChangePasswordFormOpen} onClose={toggleChangePasswordForm} />
        </>
    )
}
export default EditProfile;