import { useContext, useEffect } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo';
import { Box, Card, Drawer, styled, useTheme } from '@mui/material';
import SidebarMenu from './SidebarMenu';
import http from 'src/utils/httpHelper';
import useAuth from 'src/hooks/useAuth';

const SidebarWrapper = styled(Card)(
  ({ theme }) => `
    width: ${theme.sidebar.width};
    background: ${theme.sidebar.background};
    height: 100%;
    
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        position: fixed;
        height: calc(100% - ${theme.spacing(8)});
        margin: ${theme.spacing(4, 0, 4, 4)};
        z-index: 10;
        border-radius: ${theme.general.borderRadius};
    }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        margin: 0 ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const { setFlightPlansBadge } = useContext(SidebarContext);
  const { user } = useAuth();

  useEffect(() => {
    if (user.role_id == 1 || user.role_id == 2 || user.role_id == 5) {
      const getPendingFlightPlans = () => {
        http.get('api/pending-flight-plans-number')
          .then(response => {
            let pendingFlightPlans = response.data;
            if (pendingFlightPlans == 0) {
              setFlightPlansBadge(null);
            } else {
              setFlightPlansBadge(pendingFlightPlans + "");
            }
          });
      }
      getPendingFlightPlans();
      const interval = setInterval(() => {
        getPendingFlightPlans();
      }, 7500);

      return () => {
        clearInterval(interval);
      }
    }
  }, [])

  return (
    <>
      <SidebarWrapper
        sx={{
          display: { xs: 'none', lg: 'inline-block' }
        }}
      >
        <TopSection>
          <Logo />
        </TopSection>
        <Box
          sx={{
            height: 'calc(100% - 80px)'
          }}
        >
          <Scrollbar>
            <Box pt={1}>
              <SidebarMenu />
            </Box>
          </Scrollbar>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          display: { lg: 'none', xs: 'inline-block' }
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Logo />
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
