import { useState, useEffect, useCallback } from "react";
import PageHeader from 'src/components/PageHeader';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useTranslation } from "react-i18next";
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from "src/components/Text";
import Label from "src/components/Label";
import useAuth from "src/hooks/useAuth";
import EditProfile from "./EditProfile";
import ThemeSettings from "src/components/ThemeSettings";
import LanguageSwitcher from "./LanguageSwitcher";

function MyAccount() {
    const { t } = useTranslation();
    const { user } = useAuth();

    return (
        <>
            <PageHeader
                heading={t('MY_ACCOUNT.HEADING')}
                subheading={t('MY_ACCOUNT.SUBHEADING')}
                icon={<AccountBoxIcon fontSize="large" />}>
            </PageHeader>

            <Grid
                sx={{
                    px: 4,
                }}
                container
                direction="row"
                alignItems="stretch"
                alignContent={"space-between"}
                spacing={4}
            >
                <Grid item xs={12} lg={12}>
                    <EditProfile user={user} />
                </Grid>
                {/* <Grid item xs={12} lg={4}>
                    <ThemeSettings setting="layout" />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ThemeSettings setting="color" />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <LanguageSwitcher />
                </Grid> */}
            </Grid>
        </>
    );
}

export default MyAccount;