import { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";

import {
  Avatar,
  Box,
  Button,
  Popover,
  styled,
} from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import HeaderUserboxPopoverContent from "src/layouts/common/HeaderUserboxPopover";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(0)};
        color: ${theme.colors.alpha.trueWhite[70]};

        &:hover {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
`
);


function HeaderUserbox() {
  const { user } = useAuth();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Box
      component="span"
    >
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          alt={user.username}
          src={"/static/images/placeholders/avatar.png"}
        />
        <ExpandMoreTwoToneIcon
          fontSize="small"
          sx={{
            ml: 0.5,
          }}
        />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <HeaderUserboxPopoverContent closeCallBack={handleClose}></HeaderUserboxPopoverContent>
      </Popover>
    </Box>
  );
}

export default HeaderUserbox;
