import * as Yup from "yup";
import type { FC } from "react";
import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";
import useRefMounted from "src/hooks/useRefMounted";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import anyToString from "src/utils/anyToString";

const LoginForm: FC = () => {
  const { login } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        remember: false,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("VALIDATION.EMAIL"))
          .max(255)
          .required(t("VALIDATION.REQUIRED")),
        password: Yup.string()
          .max(255)
          .required(t("VALIDATION.REQUIRED")),
        remember: Yup.boolean()
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password, values.remember);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          enqueueSnackbar(err.message, {
            variant: "error",
          });
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email && errors.email.toString()}
            label={t("FIELDS.EMAIL")}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password && errors.password.toString()}
            label={t("FIELDS.PASSWORD")}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.remember}
                  name="remember"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t("FIELDS.REMEMBER")}
                  </Typography>
                </>
              }
            />
            <Link component={RouterLink} to="/recover-password">
              <b>{t("LOGIN.LOST_PASSWORD")}</b>
            </Link>
          </Box>

          {Boolean(touched.remember && errors.remember) && (
            <FormHelperText error>{anyToString(errors.remember)}</FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t("LOGIN.HEADING")}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
