import CrudBase from "src/components/CrudBase";
import GasolineLoadsForm from "./GasolineLoadsForm";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

export function GasolineLoads() {

    return (
        <CrudBase
            resource="gasoline-loads"
            indexColumns={['folio', 'load_date', 'quantity', 'price', 'total', 'aircraft_info', 'comment', 'loaded_by']}
            showModalHiddenColumns={['id']}
            form={GasolineLoadsForm}
            deleteButton={false}
            editButton={false}
            searchText={true}
            icon={<LocalGasStationIcon fontSize="large" />} />
    );
}
