import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import useAuth from "src/hooks/useAuth";
import http from "src/utils/httpHelper";
import { useSnackbar } from "notistack";
import { User } from "src/models/user";
import anyToString from "src/utils/anyToString";

interface EditProfileFormDialogProps {
    isOpen: boolean,
    onClose: any
}

const EditProfileFormDialog: FC<EditProfileFormDialogProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const { setUser, user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={isOpen}
                onClose={onClose}
            >
                <DialogTitle
                    sx={{
                        p: 3,
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t("MY_ACCOUNT.ACCOUNT_EDIT")}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t("MY_ACCOUNT.ACCOUNT_EDIT")}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={{
                        email: user.email,
                        username: user.username,
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .max(255)
                            .required(t("VALIDATION.REQUIRED")),
                        email: Yup.string()
                            .email(t("VALIDATION.EMAIL"))
                            .max(255)
                            .required(t("VALIDATION.REQUIRED")),
                    })}
                    onSubmit={async (
                        _values,
                        { resetForm, setErrors, setStatus, setSubmitting }
                    ) => {
                        await http.put('/user/profile-information', _values)
                            .then(async (response) => {
                                setStatus({ success: true });
                                await http.get<User>("/api/user")
                                    .then((response) => {
                                        setUser(new User(response.data));
                                        enqueueSnackbar(t('MY_ACCOUNT.PROFILE_INFO_UPDATED'), { variant: 'success' });
                                    })
                                    .catch((error) => {
                                        enqueueSnackbar(t('MY_ACCOUNT.PROFILE_INFO_UPDATED_WITH_ERROR'), { variant: 'warning' });
                                    })
                                    .finally(() => {
                                        onClose();
                                    })
                                resetForm();
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3,
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.username && errors.username)}
                                                    fullWidth
                                                    helperText={touched.username && errors.username && errors.username.toString()}
                                                    label={t("Username")}
                                                    name="username"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.username}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.email && errors.email)}
                                                    fullWidth
                                                    helperText={touched.email && errors.email && errors.email.toString()}
                                                    label={t("Email address")}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="email"
                                                    value={values.email}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3,
                                }}
                            >
                                <Button color="secondary" onClick={onClose}>
                                    {t("GENERAL.CANCEL")}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t("MY_ACCOUNT.ACCOUNT_EDIT")}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}

export default EditProfileFormDialog;