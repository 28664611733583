import { Grid } from "@mui/material";
import CrudBase from "src/components/CrudBase";
import PageHeader from "src/components/PageHeader";
import { useTranslation } from "react-i18next";
import AirportForm from "./AirportsForm";
import FlightLandIcon from '@mui/icons-material/FlightLand';

export function Airports() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="airports"
            indexColumns={['name']}
            form={AirportForm}
            showButton={false}
            icon={<FlightLandIcon fontSize="large" />} />
    );
}
