import { Box, Button, Card, Grid, TextField, Typography, useTheme } from "@mui/material";
import PageHeader from "src/components/PageHeader";
import { useTranslation } from "react-i18next";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/Dashboard';
import GasolinePriceForm from "./GasolinePriceForm";
import useAuth from "src/hooks/useAuth";
import PilotInfo from "../PilotProfile/PilotInfo";
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import moment from 'moment';
import { Navigate } from "react-router-dom";

function Dashboard() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();
  const [graphicData, setGraphicData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user.role_id == 1 || user.role_id == 2) {
      http.get('api/gasoline-inventory-movements-for-graphic')
        .then(response => setGraphicData(response.data))
        .catch(error => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  }, []);

  const gasolineMovementsOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      }
    },
    colors: [theme.colors.warning.main],
    dataLabels: {
      enabled: false
    },
    theme: {
      mode: theme.palette.mode
    },
    stroke: {
      show: true,
      colors: [theme.colors.warning.main],
      width: 2
    },
    markers: {
      size: 3,
      colors: [theme.colors.primary.main],
    },
    legend: {
      show: false
    },
    fill: {
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 0.9,
        opacityTo: 0.25,
        stops: [0, 100]
      }
    },
    labels: graphicData.map(data => moment(data.created_at).format('DD/MM/Y H:m:s')),
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false,
      min: 0
    }
  };
  const gasolineMovementsData = [
    {
      name: 'Litros de gasolina',
      data: graphicData.map(data => data.current_quantity)
    }
  ];

  if (user.role_id == 4) {
    return (<Navigate to={'/admin/gasoline-loads'}></Navigate>);
  }

  if (user.role_id == 5) {
    return (<Navigate to={'/admin/flight-plans'}></Navigate>);
  }

  return (
    <>

      {user.role_id == 3 ?
        <PageHeader heading={t('PILOT_PROFILE.HEADING')} subheading={t('PILOT_PROFILE.SUBHEADING')} icon={<ManageAccountsIcon fontSize="large" />} /> :
        <PageHeader heading={t('DASHBOARD.HEADING')} subheading={t('DASHBOARD.SUBHEADING')} icon={<DashboardIcon />} />
      }
      <Grid
        sx={{
          px: 4,
        }}
        container
        spacing={4}
      >
        {user.role_id == 3 ?
          <>
            <Grid item sm={12} md={12}>
              <Card>
                <Box p={2}>
                  <PilotInfo />
                </Box>
              </Card>
            </Grid>
          </> :
          <>
            <Grid item md={8}>
              <Card>
                <Box p={2}>
                  <Typography><h2>{t('DASHBOARD.GRAPHIC_TITLE')}</h2></Typography>
                  <Chart options={gasolineMovementsOptions} series={gasolineMovementsData} type="area" height={185} />
                </Box>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <Box p={2}>
                  <GasolinePriceForm />
                </Box>
              </Card>
            </Grid>
          </>
        }
      </Grid>
    </>
  );
}

export default Dashboard;
