import { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    Autocomplete,
    MenuItem
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import moment from "moment";
import { DesktopTimePicker } from '@mui/x-date-pickers';
import i18n from "src/i18n/i18n";

interface GasolineLoadsFormProps {
    modalCloseCallback,
    onSuccessCallback,
    data?: any
}

const calculateAmount = (price, quantity) => (price != 'NaN' && quantity != 'NaN' ? price * quantity : 0.00).toFixed(2);
const calculateLiters = (initLoad, finalLoad) => (initLoad != 'NaN' && finalLoad != 'NaN' ? (finalLoad - initLoad).toFixed(4) : '');

const GasolineLoadsForm: FC<GasolineLoadsFormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [aircrafts, setAircrafts] = useState([]);
    const [pilotProfiles, setPilotProfiles] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [lastFinalLoad, setLastFinalLoad] = useState('');
    const gasolinePrice = useRef('0.00');

    useEffect(() => {
        http.get('api/gasoline-loads-info-for-selects')
            .then(response => {
                setAircrafts(response.data.pilotAircrafts);
                setPaymentMethods(response.data.paymentMethods);
                setPilotProfiles(response.data.pilotProfiles);
                setLastFinalLoad(response.data.lastFinalLoad);
                gasolinePrice.current = response.data.lastGasolinePrice;
            })
            .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });

    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    load_date: moment().format('YYYY-MM-DD'),
                    init_load: lastFinalLoad,
                    init_load_time: moment().format('H:m'),
                    final_load: "",
                    final_load_time: "",
                    quantity: "",
                    price: gasolinePrice.current,
                    amount: "",
                    payment_method: "",
                    pilot_aircraft_id: '',
                    pilot_profile_id: '',
                    invoice: '',
                    comment: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    load_date: Yup.date()
                        .required(t("VALIDATION.REQUIRED")),
                    pilot_aircraft_id: Yup.mixed()
                        .required(t("VALIDATION.REQUIRED")),
                    quantity: Yup.number()
                        .required(t("VALIDATION.REQUIRED"))
                        .min(1, t('VALIDATION.MIN_ONE')),
                    price: Yup.number()
                        .required(t("VALIDATION.REQUIRED"))
                        .min(1, t('VALIDATION.MIN_ONE')),
                    init_load_time: Yup.string().required(i18n.t('VALIDATION.REQUIRED')).matches(/^(0[0-9]|[0-9][0-9]):[0-5][0-9]$/, 'Formato invalido, debe ser HH:MM'),
                    init_load: Yup.number()
                        .required(t("VALIDATION.REQUIRED"))
                        .min(0),
                    final_load: Yup.number()
                        .required(t("VALIDATION.REQUIRED"))
                        .min(1, t('VALIDATION.MIN_ONE')),
                    final_load_time: Yup.string()
                        .required(i18n.t('VALIDATION.REQUIRED')).matches(/^(0[0-9]|[0-9][0-9]):[0-5][0-9]$/, 'Formato invalido, debe ser HH:MM'),
                    pilot_profile_id: Yup.mixed()
                        .required(t("VALIDATION.REQUIRED")),
                    payment_method: Yup.mixed()
                        .required(t("VALIDATION.REQUIRED")),
                    invoice: Yup.mixed()
                        .required(t("VALIDATION.REQUIRED"))
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    http.post('api/gasoline-loads', _values)
                        .then((response: any) => {
                            setStatus({ success: true });
                            resetForm();
                            onSuccessCallback()
                            enqueueSnackbar(t('GASOLINE-LOADS.SAVED'), { variant: 'success' });
                            setLastFinalLoad(response.data.data);
                        })
                        .catch((error) => {
                            console.error(error);
                            enqueueSnackbar(error.message, { variant: 'error' });
                            setStatus({ success: false });
                            setErrors({ submit: error.message });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.load_date && errors.load_date)}
                                        type={"date"}
                                        fullWidth
                                        helperText={touched.load_date && errors.load_date && errors.load_date.toString()}
                                        label={t("FIELDS.LOAD_DATE")}
                                        name="load_date"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.load_date}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Autocomplete
                                        options={aircrafts}
                                        onBlur={() => setTouched({ ['pilot_aircraft_id']: true })}
                                        onChange={(_, option: any) => setFieldValue('pilot_aircraft_id', option ? option.id : null)}
                                        isOptionEqualToValue={(option, current) => option.id === current.id}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.AIRCRAFT')}
                                                helperText={touched.pilot_aircraft_id && errors.pilot_aircraft_id && errors.pilot_aircraft_id.toString()}
                                                error={Boolean(touched.pilot_aircraft_id && errors.pilot_aircraft_id)}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.quantity && errors.quantity)}
                                        type={'number'}
                                        fullWidth
                                        helperText={touched.quantity && errors.quantity && errors.quantity.toString()}
                                        label={t("FIELDS.QUANTITY")}
                                        name="quantity"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.quantity}
                                        variant="outlined"
                                        InputProps={{ inputProps: { min: 0, step: '.0001' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.price && errors.price)}
                                        type={'number'}
                                        fullWidth
                                        helperText={touched.price && errors.price && errors.price.toString()}
                                        label={t("FIELDS.PRICE")}
                                        name="price"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.price}
                                        variant="outlined"
                                        InputProps={{ inputProps: { min: 0, step: '.01' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        type={'number'}
                                        fullWidth
                                        label={t("FIELDS.AMOUNT")}
                                        value={calculateAmount(parseFloat(values.price ? values.price : '0.00'), parseFloat(values.quantity ? values.quantity : '0.00'))}
                                        variant="outlined"
                                        disabled={true}
                                        InputProps={{ inputProps: { min: 0, step: '.0001' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.init_load_time && errors.init_load_time)}
                                        fullWidth
                                        helperText={touched.init_load_time && errors.init_load_time && errors.init_load_time.toString()}
                                        label={t("FIELDS.INIT_LOAD_TIME")}
                                        name="init_load_time"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.init_load_time}
                                        variant="outlined"
                                        placeholder="HH:MM"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.init_load && errors.init_load)}
                                        type={'number'}
                                        fullWidth
                                        helperText={touched.init_load && errors.init_load && errors.init_load.toString()}
                                        label={t("FIELDS.INIT_LOAD")}
                                        name="init_load"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.init_load}
                                        variant="outlined"
                                        InputProps={{ inputProps: { min: 0, step: '.0001' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.final_load && errors.final_load
                                        )}
                                        type={'number'}
                                        fullWidth
                                        helperText={touched.final_load && errors.final_load && errors.final_load.toString()}
                                        label={t("FIELDS.FINAL_LOAD")}
                                        name="final_load"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.final_load}
                                        variant="outlined"
                                        InputProps={{ inputProps: { min: 0, step: '.0001' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.final_load_time && errors.final_load_time)}
                                        fullWidth
                                        helperText={touched.final_load_time && errors.final_load_time && errors.final_load_time.toString()}
                                        label={t("FIELDS.FINAL_LOAD_TIME")}
                                        name="final_load_time"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.final_load_time}
                                        variant="outlined"
                                        placeholder="HH:MM"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        type={'number'}
                                        fullWidth
                                        label={t("FIELDS.LITERS")}
                                        value={calculateLiters(parseFloat(values.init_load), parseFloat(values.final_load))}
                                        variant="outlined"
                                        disabled={true}
                                        InputProps={{ inputProps: { min: 0, step: '.0001' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Autocomplete
                                        options={pilotProfiles}
                                        onBlur={() => setTouched({ ...touched, pilot_profile_id: true })}
                                        onChange={(_, option: any) => {
                                            if (option) {
                                                setFieldValue('pilot_profile_id', option.id);
                                                if (option.id != 1) {
                                                    setFieldValue('comment', option.label);
                                                }
                                            } else {
                                                setFieldValue('pilot_profile_id', '');
                                                setFieldValue('comment', '');
                                            }
                                        }}
                                        isOptionEqualToValue={(option, current) => option.id === current.id}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.PILOT')}
                                                helperText={touched.pilot_profile_id && errors.pilot_profile_id && errors.pilot_profile_id.toString()}
                                                error={Boolean(touched.pilot_profile_id && errors.pilot_profile_id)}
                                            />
                                        )}
                                    />
                                </Grid>
                                {values.pilot_profile_id == '1' && <Grid item xs={12} md={12}>
                                    <TextField
                                        type={"text"}
                                        fullWidth
                                        helperText={t('GASOLINE-LOADS.COMMENT')}
                                        label={t("FIELDS.COMMENT")}
                                        name="comment"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.comment}
                                        variant="outlined"
                                    />
                                </Grid>}
                                <Grid item xs={12} md={12}>
                                    <Autocomplete
                                        options={paymentMethods}
                                        onBlur={() => setTouched({ ...touched, payment_method: true })}
                                        onChange={(_, option: any) => setFieldValue('payment_method', option)}
                                        renderInput={props => (
                                            <TextField
                                                {...props}
                                                label={t('FIELDS.PAYMENT_METHOD')}
                                                helperText={touched.payment_method && errors.payment_method && errors.payment_method.toString()}
                                                error={Boolean(touched.payment_method && errors.payment_method)}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        select
                                        fullWidth
                                        error={Boolean(touched.invoice && errors.invoice)}
                                        helperText={touched.invoice && errors.invoice && errors.invoice.toString()}
                                        label={t("FIELDS.INVOICE")}
                                        name="invoice"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.invoice}
                                        variant="outlined">
                                        <MenuItem value="Sí">Sí</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                            }}
                        >
                            <Button color="secondary" onClick={modalCloseCallback}>
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={Boolean(errors.submit) || isSubmitting}
                                variant="contained"
                            >
                                {t(!data ? "GASOLINE-LOADS.CREATE" : 'GASOLINE-LOADS.EDIT')}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default GasolineLoadsForm;